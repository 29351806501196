import { FC, useState } from "react";

import { ReactComponent as SettingsIcon } from "@material-symbols/svg-400/rounded/settings.svg";
import { format as formatFN } from "date-fns";
import { ru } from "date-fns/locale";

import classes from "./DashboardSettings.module.scss";

import { SetupDashboard } from "features/SetupDashboard";

import { Button } from "shared/ui/Button";
import { Card } from "shared/ui/Card";
import { Modal } from "shared/ui/Modal";
import { Text } from "shared/ui/Text";

export const DashboardSettings: FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Card className={classes.settings}>
        <Text bold="semi" size="xl" height="big">
          {formatFN(new Date(), "d MMMM yyyy", { locale: ru })}
        </Text>
        <Button onClick={() => setOpen(true)} theme="secondary" size="medium" iconLeft={<SettingsIcon />}>
          Настройки
        </Button>
      </Card>
      <Modal
        noBodyPadding
        bodyClassName={classes["modal-body"]}
        open={open}
        setOpen={setOpen}
        showClose
        title="Отображение блоков"
        dialogClassName={classes.modal}
      >
        <SetupDashboard setOpen={setOpen} />
      </Modal>
    </>
  );
};
