import { FC } from "react";

import cn from "classnames";
import { PlacesType } from "react-tooltip";

import classes from "../Tooltip.module.scss";

interface Props {
  place: PlacesType;
  theme: "light" | "dark";
}

export const Arrow: FC<Props> = ({ place, theme }) => {
  return (
    <span className={cn(classes.arrow, classes[`arrow-${theme}`], classes[`arrow-${place}`])}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" width={12} height={6}>
        <path d="M4.474 5.291a1.997 1.997 0 0 0 3.052 0L9.21 3.3a1.84 1.84 0 0 0 .014-.017L12 0H0l2.776 3.283.014.017L4.474 5.29Z" />
      </svg>
    </span>
  );
};
