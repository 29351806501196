import { FC } from "react";

import { Col, Grid } from "shared/ui/Grid";
import { Skeleton } from "shared/ui/Skeleton";

export const PageSkeleton: FC = () => {
  return (
    <Grid justify="between" lg={{ direction: "column-reverse" }}>
      <Col span={4} lg={{ span: 12 }}>
        <Skeleton rows={45} />
      </Col>
      <Col span={4} lg={{ span: 12 }}>
        <Skeleton rows={45} />
      </Col>
      <Col span={4} lg={{ span: 12 }}>
        <Skeleton rows={4} />
        <Skeleton rows={10} />
      </Col>
    </Grid>
  );
};
