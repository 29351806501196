// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_navigation__ELzpP {
  display: flex;
  gap: 24px;
  align-items: stretch;
  height: 100%;
}
@media (max-width: 992px) {
  .Navigation_navigation__ELzpP {
    gap: 12px;
  }
}
@media (max-width: 768px) {
  .Navigation_navigation__ELzpP {
    display: none;
  }
  .Navigation_navigation__ELzpP.Navigation_mobile__C0Lmb {
    display: block;
    padding-top: 20px;
  }
  .Navigation_navigation__ELzpP.Navigation_mobile__C0Lmb .Navigation_nav-link__V76qS::after {
    content: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/Header/components/Navigation/Navigation.module.scss","webpack://./src/shared/assets/helpers/media.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,oBAAA;EACA,YAAA;AADF;ACcE;EDjBF;IAOI,SAAA;EAAF;AACF;ACGE;EDXF;IAWI,aAAA;EACF;EACE;IACE,cAAA;IACA,iBAAA;EACJ;EAEM;IACE,aAAA;EAAR;AACF","sourcesContent":["@import \"shared/assets/styles\";\n\n.navigation {\n  display: flex;\n  gap: 24px;\n  align-items: stretch;\n  height: 100%;\n\n  @include tablet {\n    gap: 12px;\n  }\n\n  @include small-tablet {\n    display: none;\n\n    &.mobile {\n      display: block;\n      padding-top: 20px;\n\n      .nav-link {\n        &::after {\n          content: none;\n        }\n      }\n    }\n  }\n}\n","$sm: 576px;\n$md: 768px;\n$lg: 992px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin phone {\n  @media (max-width: $sm) {\n    @content;\n  }\n}\n\n@mixin small-tablet {\n  @media (max-width: $md) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: $lg) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: $xl) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (max-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `Navigation_navigation__ELzpP`,
	"mobile": `Navigation_mobile__C0Lmb`,
	"nav-link": `Navigation_nav-link__V76qS`
};
export default ___CSS_LOADER_EXPORT___;
