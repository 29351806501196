import { makeAutoObservable } from "mobx";

import { StatisticsFiltersApi } from "shared/api";

const { getDefaultFilter, getFilter, saveFilter } = StatisticsFiltersApi();

export class StatisticsFiltersStore {
  hash: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadFilters = async () => {
    return this.hash ? getFilter(this.hash) : getDefaultFilter();
  };

  saveFilters = async (filters: any) => {
    const result = await saveFilter(filters);
    if (result.isSuccess) {
      this.hash = result.result.hash;
      return this.hash;
    }
  };

  updateHash = (newHash: string | null) => {
    this.hash = newHash;
  };
}
