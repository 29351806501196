import { FC, ReactNode } from "react";

import cn from "classnames";

import { BaseButtonProps, BaseButton } from "../BaseButton";

import classes from "./Button.module.scss";

interface Props extends Omit<BaseButtonProps, "classNames"> {
  /** Класс для кастомизации */
  className?: string;
  /** Вид кнопки */
  variant?: "default" | "text" | "link";
  /** Размер кнопки */
  size?: "small" | "medium" | "big";
  /** Цветовая тема кнопки */
  theme?: "primary" | "secondary";
  /** Левая иконка */
  iconLeft?: ReactNode;
  /** Правая иконка */
  iconRight?: ReactNode;
  /** Иконка */
  icon?: ReactNode;
  /** Ширина кнопки по контейнеру */
  fullWidth?: boolean;
}

interface IconsProps {
  icon?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  size?: "small" | "medium" | "big";
  children?: ReactNode;
}

const renderContent: FC<IconsProps> = ({ icon, iconLeft, iconRight, size, children }) => {
  return (
    <>
      {iconLeft && !icon && <span className={cn(classes.icon, classes[`icon-${size}`])}>{iconLeft}</span>}
      {icon ? <span className={cn(classes.icon, classes[`icon-${size}`])}>{icon}</span> : children}
      {iconRight && !icon && <span className={cn(classes.icon, classes[`icon-${size}`])}>{iconRight}</span>}
    </>
  );
};

/**
 * ### Разработка
 * - Свойства `iconLeft`, `iconRight` и `children` не работают если выбрано свойство `icon`.
 * - Для кастомизации можно использовать свойство className
 *
 */

export const Button: FC<Props> = ({
  className,
  children,
  onClick,
  variant = "default",
  theme = "primary",
  size = "big",
  type = "button",
  target = "_self",
  disabled = false,
  fullWidth = false,
  download = false,
  iconLeft,
  iconRight,
  icon,
  href,
  dataTestid,
}) => {
  const classNames = cn(
    classes.container,
    classes[`container-${size}`],
    classes[`${variant}-button`],
    classes[`${variant}-button-size-${size}`],
    classes[`${variant}-button-theme-${theme}`],
    icon && classes[`icon-button-size-${size}`],
    fullWidth && classes["full-width"],
    disabled && classes[`${variant}-button-theme-${theme}-disabled`],
    className,
  );

  return (
    <BaseButton
      href={href}
      dataTestid={dataTestid}
      download={download}
      target={target}
      type={type}
      className={classNames}
      onClick={onClick}
      disabled={disabled}
    >
      {renderContent({ icon, iconLeft, iconRight, size, children })}
    </BaseButton>
  );
};
