import { DashboardItem } from "../model";

export const dashboardMock: DashboardItem[] = [
  {
    id: 1,
    name: "topAffiliates",
    column: 0,
    order: 0,
    state: 1,
  },
  {
    id: 2,
    name: "topOffers",
    column: 0,
    order: 1,
    state: 1,
  },
  {
    id: 3,
    name: "manager",
    column: 2,
    order: 0,
    state: 1,
  },
];
