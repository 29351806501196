import { FC, useEffect, useState } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as DragIcon } from "@material-symbols/svg-400/rounded/drag_indicator.svg";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";

import classes from "../Dnd.module.scss";
import { SortableItemProps } from "../DnD.types";

import { useWindowSize } from "shared/hooks/useWindowSize";
import { Select } from "shared/ui/Select";
import { DefaultOption } from "shared/ui/Select/Select.types";
import { Text } from "shared/ui/Text";

export const SortableItem: FC<SortableItemProps> = observer(({ item, className, grabbing }) => {
  const [selectOptions, setSelectOptions] = useState<DefaultOption[]>();
  const { id, children, title, variant = "dashboard", isDraggable = true } = item;
  const { isTablet } = useWindowSize();
  const {
    affiliateStore: { affiliateRating, updateAffiliateRating },
    offerStore: { offerRating, updateOfferRating },
  } = useStore();
  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: id,
    data: {
      type: "Item",
      data: item,
    },
  });

  useEffect(() => {
    if (item.name === "topAffiliates" && affiliateRating) {
      setSelectOptions(affiliateRating);
    }
    if (item.name === "topOffers" && offerRating) {
      setSelectOptions(offerRating);
    }
  }, [affiliateRating, offerRating]);

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    const itemHeight = document.getElementById(`item-${id}`)?.clientHeight;
    return <div className={classes.overlay} style={{ height: itemHeight }} />;
  }

  return (
    <div
      ref={setNodeRef}
      id={`item-${id}`}
      data-testid={`${id}-item-test`}
      style={isDraggable ? style : {}}
      className={cn(classes.item, classes[variant], className)}
    >
      <div className={classes.head}>
        <div className={classes.left}>
          {isDraggable && !isTablet && (
            <div
              data-testid={`${id}-drag-test`}
              className={cn(classes["drag-icon"], grabbing && classes.grabbing)}
              {...attributes}
              {...listeners}
            >
              <DragIcon />
            </div>
          )}
          {variant === "dashboard" && (
            <Text bold="semi" size="xl" height="big">
              {title}
            </Text>
          )}
        </div>
        {(item.name === "topAffiliates" || item.name === "topOffers") &&
          selectOptions &&
          selectOptions?.length !== 0 && (
            <Select
              options={selectOptions}
              size="small"
              isClearable={false}
              defaultValue={selectOptions[0]}
              onChange={(option) => {
                if (item.name === "topAffiliates") {
                  updateAffiliateRating(option.value);
                } else {
                  updateOfferRating(option.value);
                }
              }}
            />
          )}
      </div>
      {children}
    </div>
  );
});
