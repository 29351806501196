import { makeAutoObservable } from "mobx";

import { MangerProps } from "./model";

import { ManagerApi } from "shared/api/Manager";
import { Result } from "shared/api/request";

const { getManager } = ManagerApi();

export class ManagerStore {
  manager: MangerProps | undefined = undefined;
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }

  load = async () => {
    const result: Result = await getManager();
    if (result.isSuccess) {
      this.manager = { ...result.result };
    }
    this.loading = false;
  };
}
