import { ReactNode } from "react";

import { ReactComponent as Account } from "@material-symbols/svg-400/rounded/account_circle.svg";
import { ReactComponent as Download } from "@material-symbols/svg-400/rounded/download.svg";

interface Props {
  id: number;
  path?: string;
  label: ReactNode;
  hasCount?: boolean;
  icon?: ReactNode;
  links?: {
    id: number;
    path: string;
    label: ReactNode;
    hasCount?: boolean;
  }[];
}

export const routes: Props[] = [
  {
    id: 1,
    path: "/dashboard",
    label: "Главная",
  },
  {
    id: 3,
    label: "Офферы",
    path: "/offers",
  },
  {
    id: 2,
    label: "Статистика",
    path: "/statistics",
  },
];

export const profileRoutes: Props[] = [
  {
    id: 11,
    path: "/profile",
    label: "Мои данные",
    icon: <Account />,
  },
  {
    id: 12,
    path: "/profile/history",
    label: "История экспорта",
    icon: <Download />,
  },
];
