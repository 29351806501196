import { ColumnProps, Order, RowSelection } from "./Table.types";

export const setupColumns = (
  columns: ColumnProps[],
  sortingOrder: Order,
  sortingKey: string,
  rowSelection?: RowSelection | undefined,
) => {
  let newColumns: any = [];
  if (rowSelection) {
    newColumns = [
      {
        title: "",
        key: "checkbox",
        dataIndex: "checkbox",
        width: 70,
        onHeaderCell: (record: any) => ({
          ...record,
          rowSelection: rowSelection,
        }),
        onCell: (record: any) => ({
          id: record.id,
          isSummary: record.isSummary,
          isCheckbox: record.checkbox,
          width: 70,
          align: "center",
          key: "checkbox",
          dataIndex: "checkbox",
          rowSelection: rowSelection,
        }),
      },
    ];
  }
  newColumns = [
    ...newColumns,
    ...columns.map((column) => ({
      title: column.title,
      dataIndex: column.key,
      key: column.key,
      align: column.align,
      width: column.width,
      onHeaderCell: (record: any) => {
        return {
          ...record,
          ellipsis: column.ellipsis,
          isSortable: column.isSortable,
          tooltip: column.tooltipText,
          nowrap: column.nowrap,
          sortingOrder,
          sortingKey,
        };
      },
      onCell: (record: any, rowIndex: number) => {
        return {
          ...record,
          id: record.id,
          ellipsis: column.ellipsis,
          rowSpan: column.rowSpan && column.rowSpan(rowIndex),
          colSpan: column.colSpan && column.colSpan(rowIndex),
          href: column.href,
          align: column.align,
          width: column.width,
          onCellClick: column.onCellClick,
        };
      },
    })),
  ];

  return newColumns;
};
