import { get } from "../request";

import { convertObjectToQueryString, mapToDefaultOptions } from "shared/utils";

export const OfferApi = () => {
  const getOffers = async (params: Record<string, any>) => {
    return await get(`v1/offer${convertObjectToQueryString(params)}`);
  };

  const getOfferStatuses = async () => {
    return await get("v1/offer/status");
  };

  const getOfferChannels = async () => {
    return await get("v1/offer/channel");
  };

  const getOfferSorting = async () => {
    return await get("v1/offer/sort");
  };

  const getTopOffers = async (rating: number | null) => {
    return await get(`v1/top/offers?rating=${rating}`);
  };

  const getTopOffersRating = async () => {
    const result = await get("v1/top/offer-rating");

    if (result.isSuccess && result.result) {
      return {
        ...result,
        result: mapToDefaultOptions(result.result, (data) => {
          return Object.entries(data).map(([key, value]) => ({
            value: Number(key),
            label: `Рейтинг по ${value}`,
          }));
        }),
      };
    }
    return result;
  };

  return {
    getOffers,
    getTopOffers,
    getOfferSorting,
    getOfferStatuses,
    getOfferChannels,
    getTopOffersRating,
  };
};
