import { FC, useMemo } from "react";

import { rectSwappingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";
import { VisibilityStates } from "entities/Dashboard/model";

import classes from "../Dnd.module.scss";
import { ColumnComponentProps, ItemProps } from "../DnD.types";

import { SortableItem } from "./SortableItem";

export const Column: FC<ColumnComponentProps> = observer(({ id, items }) => {
  const ids = useMemo(() => (items ? items.map((item) => item.id) : []), [id, items]);
  const {
    dashboardStore: { visibleItems },
  } = useStore();
  const { setNodeRef } = useSortable({
    id: id,
    data: {
      type: "Column",
    },
  });

  return (
    <div ref={setNodeRef} className={classes.column} data-testid={`${id}-column-test`}>
      <SortableContext strategy={rectSwappingStrategy} items={ids}>
        {items &&
          items.map(
            (item: ItemProps) =>
              visibleItems[item.name as keyof VisibilityStates] && <SortableItem key={item.id} item={item} />,
          )}
      </SortableContext>
    </div>
  );
});
