import { FC, useState, useRef, ReactNode } from "react";

import { ReactComponent as Expand } from "@material-symbols/svg-400/rounded/expand_more.svg";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

import classes from "./Dropdown.module.scss";

interface Link {
  id: number;
  label: ReactNode;
  path: string;
  hasCount?: boolean;
}

interface Props {
  className?: string;
  label: ReactNode;
  active?: boolean;
  content?: Link[];
  count?: number | undefined;
  setOpenBurger?: (val: boolean) => void;
}

export const Dropdown: FC<Props> = ({ className, label, count, content, active = false, setOpenBurger }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div ref={ref} className={cn(classes.container, className)}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={cn(classes.label, open && classes.open, active && classes.active)}
      >
        {Boolean(count) && <div className={classes.count}>{count}</div>}
        {label}
        <Expand />
      </button>
      {open && content && (
        <div data-testid="dropdown-links-test" className={classes.content}>
          {content.map((link) => (
            <NavLink
              onClick={() => {
                setOpen(false);
                setOpenBurger && setOpenBurger(false);
              }}
              key={link.id}
              className={classes.link}
              to={link.path}
            >
              {Boolean(link.hasCount && count) && <div className={classes.count}>{count}</div>}
              {link.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};
