// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileNavigation_navigation__KeJyJ {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
@media (max-width: 992px) {
  .ProfileNavigation_navigation__KeJyJ {
    width: 100%;
    overflow: auto;
    gap: 12px;
    flex-direction: row;
    align-items: center;
  }
}

.ProfileNavigation_link__8GJ\\+\\+ {
  display: flex;
  padding: 7px 8px;
  gap: 6px;
  width: 100%;
  white-space: nowrap;
}
.ProfileNavigation_link__8GJ\\+\\+ svg {
  width: 18px;
  height: 18px;
  fill: currentcolor;
}
@media (max-width: 992px) {
  .ProfileNavigation_link__8GJ\\+\\+ {
    width: -moz-fit-content;
    width: fit-content;
  }
}

.ProfileNavigation_exit__kSGX5 {
  margin-top: 18px;
}
@media (max-width: 992px) {
  .ProfileNavigation_exit__kSGX5 {
    margin-top: 0;
    margin-left: 12px;
  }
}

.ProfileNavigation_confirm__tRNds .ProfileNavigation_icon__C53Ri {
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/ProfileNavigation/ProfileNavigation.module.scss","webpack://./src/shared/assets/helpers/media.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AADF;ACeE;EDjBF;IAMI,WAAA;IACA,cAAA;IACA,SAAA;IACA,mBAAA;IACA,mBAAA;EAAF;AACF;;AAGA;EACE,aAAA;EACA,gBAAA;EACA,QAAA;EACA,WAAA;EACA,mBAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;ACPE;EDHF;IAcI,uBAAA;IAAA,kBAAA;EAAF;AACF;;AAGA;EACE,gBAAA;AAAF;AChBE;EDeF;IAII,aAAA;IACA,iBAAA;EACF;AACF;;AAGE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAAJ","sourcesContent":["@import \"shared/assets/styles\";\n\n.navigation {\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n\n  @include tablet {\n    width: 100%;\n    overflow: auto;\n    gap: 12px;\n    flex-direction: row;\n    align-items: center;\n  }\n}\n\n.link {\n  display: flex;\n  padding: 7px 8px;\n  gap: 6px;\n  width: 100%;\n  white-space: nowrap;\n\n  svg {\n    width: 18px;\n    height: 18px;\n    fill: currentcolor;\n  }\n\n  @include tablet {\n    width: fit-content;\n  }\n}\n\n.exit {\n  margin-top: 18px;\n\n  @include tablet {\n    margin-top: 0;\n    margin-left: 12px;\n  }\n}\n\n.confirm {\n  .icon {\n    width: 48px;\n    height: 48px;\n    margin-bottom: 12px;\n  }\n}\n","$sm: 576px;\n$md: 768px;\n$lg: 992px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin phone {\n  @media (max-width: $sm) {\n    @content;\n  }\n}\n\n@mixin small-tablet {\n  @media (max-width: $md) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: $lg) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: $xl) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (max-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `ProfileNavigation_navigation__KeJyJ`,
	"link": `ProfileNavigation_link__8GJ++`,
	"exit": `ProfileNavigation_exit__kSGX5`,
	"confirm": `ProfileNavigation_confirm__tRNds`,
	"icon": `ProfileNavigation_icon__C53Ri`
};
export default ___CSS_LOADER_EXPORT___;
