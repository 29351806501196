import { get, post } from "../request";

import { NewPassword } from "./types";

export const UserApi = () => {
  const getUser = async () => {
    return await get("v1/profile");
  };

  const editPassword = async (data: NewPassword) => {
    return await post("v1/user/edit-password", data);
  };

  const logoutUser = async () => {
    return await get("v1/user/logout");
  };

  return {
    getUser,
    editPassword,
    logoutUser,
  };
};
