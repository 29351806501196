// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileLayout_content__Lz\\+hP {
  margin-top: 32px;
}
@media (max-width: 992px) {
  .ProfileLayout_content__Lz\\+hP {
    gap: 42px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/layouts/ProfileLayout/ProfileLayout.module.scss","webpack://./src/shared/assets/helpers/media.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;ACiBE;EDjBF;IAII,SAAA;EAAF;AACF","sourcesContent":["@import \"shared/assets/styles\";\n\n.content {\n  margin-top: 32px;\n\n  @include tablet {\n    gap: 42px;\n  }\n}\n","$sm: 576px;\n$md: 768px;\n$lg: 992px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin phone {\n  @media (max-width: $sm) {\n    @content;\n  }\n}\n\n@mixin small-tablet {\n  @media (max-width: $md) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: $lg) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: $xl) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (max-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `ProfileLayout_content__Lz+hP`
};
export default ___CSS_LOADER_EXPORT___;
