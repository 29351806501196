// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SetupDashboard_text__8rwI- {
  margin: 24px 0;
  padding: 0 24px;
}

.SetupDashboard_form__zpOH\\+ {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.SetupDashboard_fields__vuowc {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
}

.SetupDashboard_buttons__aaMq1 {
  display: flex;
  gap: 12px;
  justify-content: right;
  padding: 16px 24px;
  border-top: 1px solid #e9e9e9;
}`, "",{"version":3,"sources":["webpack://./src/features/SetupDashboard/SetupDashboard.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,6BAAA;AADF","sourcesContent":["@import \"shared/assets/styles\";\r\n\r\n.text {\r\n  margin: 24px 0;\r\n  padding: 0 24px;\r\n}\r\n\r\n.form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 24px;\r\n}\r\n\r\n.fields {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 16px;\r\n  padding: 0 24px;\r\n}\r\n\r\n.buttons {\r\n  display: flex;\r\n  gap: 12px;\r\n  justify-content: right;\r\n  padding: 16px 24px;\r\n  border-top: 1px solid $gray-10;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `SetupDashboard_text__8rwI-`,
	"form": `SetupDashboard_form__zpOH+`,
	"fields": `SetupDashboard_fields__vuowc`,
	"buttons": `SetupDashboard_buttons__aaMq1`
};
export default ___CSS_LOADER_EXPORT___;
