import { makeAutoObservable } from "mobx";

import { dashboardMock } from "./mock";
import { DashboardItem, Ids, VisibilityStates } from "./model";

import { DashboardApi } from "shared/api";
import { Settings } from "shared/api/Dashboard/types";
import { Result } from "shared/api/request";

const { getDashboard, updateDashboardLayout, updateDashboardVisibility } = DashboardApi();

export class DashboardStore {
  data: DashboardItem[] | [] = [];
  ids: Ids = {
    manager: 0,
    topAffiliates: 0,
    topOffers: 0,
  };
  visibleItems: VisibilityStates = {
    topAffiliates: true,
    topOffers: true,
    manager: true,
  };
  loading = false;

  constructor() {
    makeAutoObservable(this);
    if (this.data.length === 0) {
      this.loading = true;
    }
  }

  load = async () => {
    const result: Result = await getDashboard();
    if (result.isSuccess) {
      const res = result.isSuccess ? result.result.blockItems : dashboardMock;
      const firstColumn = res
        .filter((item: DashboardItem) => item.column === 0)
        .sort((a: DashboardItem, b: DashboardItem) => a.order - b.order);
      const secondColumn = res
        .filter((item: DashboardItem) => item.column === 1)
        .sort((a: DashboardItem, b: DashboardItem) => a.order - b.order);
      const thirdColumn = res
        .filter((item: DashboardItem) => item.column === 2)
        .sort((a: DashboardItem, b: DashboardItem) => a.order - b.order);

      this.data = [...firstColumn, ...secondColumn, ...thirdColumn];
      res.forEach((item: DashboardItem) => {
        this.ids[item.name as keyof Ids] = item.id;
        this.visibleItems[item.name as keyof VisibilityStates] = Boolean(item.state);
      });

      this.loading = false;
    }
  };

  updateLayout = async (val: any) => {
    const layout: Settings = {
      firstColumn: val.filter((item: DashboardItem) => item.column === 0).map((item: DashboardItem) => item.id),
      secondColumn: val.filter((item: DashboardItem) => item.column === 1).map((item: DashboardItem) => item.id),
      thirdColumn: val.filter((item: DashboardItem) => item.column === 2).map((item: DashboardItem) => item.id),
    };

    return await updateDashboardLayout(layout);
  };

  updateVisibility = async (val: VisibilityStates) => {
    this.visibleItems = val;
    const data: Settings = {
      visibleIds: [],
      invisibleIds: [],
    };
    Object.keys(val).forEach((key) => {
      if (val[key as keyof VisibilityStates]) {
        data.visibleIds.push(this.ids[key as keyof Ids]);
      } else {
        data.invisibleIds.push(this.ids[key as keyof Ids]);
      }
    });

    return await updateDashboardVisibility(data);
  };
}
