import { FC } from "react";

import { ReactComponent as Close } from "@material-symbols/svg-400/rounded/close.svg";
import cn from "classnames";

import classes from "../BaseModal.module.scss";
import { HeaderProps } from "../BaseModal.types";

import { Button } from "shared/ui/Button";

export const Header: FC<HeaderProps> = ({ title, titleClassName, headerClassName, showClose, setOpen }) => {
  if (Boolean(title) || showClose) {
    return (
      <div data-testid="modal-header" className={cn(classes.header, headerClassName)}>
        {Boolean(title) && <h3 className={cn(classes.title, titleClassName)}>{title}</h3>}
        {showClose && (
          <Button
            variant="text"
            theme="secondary"
            size="big"
            className={classes.close}
            icon={<Close />}
            onClick={() => setOpen && setOpen(false)}
          />
        )}
      </div>
    );
  }
  return null;
};
