import { makeAutoObservable } from "mobx";

import { UserProps } from "./model";

import { UserApi } from "shared/api";
import { Result } from "shared/api/request";
import { NewPassword } from "shared/api/User/types";

const { getUser, editPassword, logoutUser } = UserApi();

export class UserStore {
  user: UserProps | undefined = undefined;
  loading = false;

  constructor() {
    makeAutoObservable(this);
    if (this.user === undefined) {
      this.loading = true;
      this.load();
    }
  }

  load = async () => {
    const result: Result = await getUser();
    if (result.isSuccess) {
      this.user = { ...result.result };
      this.loading = false;
    }
  };

  getId = (): number | null => {
    return this.user?.id || null;
  };

  getLogin = (): string => {
    return this.user?.login || "";
  };

  getName = (): string => {
    return this.user?.name || "";
  };

  updatePassword = async (data: NewPassword) => {
    return await editPassword(data);
  };

  logout = async () => {
    return await logoutUser();
  };
}
