import { get } from "../request";

import { convertObjectToQueryString } from "shared/utils";

export const StatisticsAffiliatesApi = () => {
  const getData = async (params: Record<string, any>) => {
    const query = convertObjectToQueryString(params);
    return await get(`v1/statistic/affiliates${query}`);
  };

  return { getData };
};
