import { get, post } from "../request";

import { Settings } from "./types";

export const DashboardApi = () => {
  const getDashboard = async () => {
    return await get("v1/dashboard");
  };

  const updateDashboardLayout = async (data: Settings) => {
    return await post("v1/dashboard/update", data);
  };

  const updateDashboardVisibility = async (data: Settings) => {
    return await post("v1/dashboard-setting/update", data);
  };

  return {
    getDashboard,
    updateDashboardLayout,
    updateDashboardVisibility,
  };
};
