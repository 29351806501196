import { makeAutoObservable } from "mobx";

import { SortOrderType } from "entities/StatisticsData/model";
import {
  getValueFromFilters,
  getValueFromOptions,
  getValuesFromFilters,
  getValuesFromOptions,
} from "entities/StatisticsData/utils";

import { OpponentsFiltersValue, OpponentAffiliatesProps, OpponentProps } from "./model";
import { opponentsAffiliatesForTable, opponentsForTable } from "./utils";

import { StatisticsOpponentsApi } from "shared/api";
import { DefaultOption } from "shared/ui/Select";
import { convertObjectToQueryString } from "shared/utils";

const { getOpponents, getOpponentsAffiliates, getOpponent } = StatisticsOpponentsApi();

export class StatisticsOpponentsStore {
  opponents: OpponentProps[] | undefined = undefined;
  opponentsAffiliates: OpponentAffiliatesProps[] | undefined = undefined;
  opponentName = "";

  filters: OpponentsFiltersValue | null = null;
  name = "";
  page = 1;
  pageSize = 10;
  totalCount = 0;
  sortParam: string | null = "name";
  sortDirection: SortOrderType = 4;

  loading = false;

  constructor(name: string, sortDirection: SortOrderType) {
    makeAutoObservable(this);
    this.sortParam = name;
    this.sortDirection = sortDirection;
  }

  loadOpponents = async () => {
    this.loading = true;
    const result = await getOpponents(this.getParamsForLoadData());
    if (result.isSuccess) {
      this.opponents = [...result.result.items];
      this.totalCount = result.result.pagination.totalCount;
    }
    if (result.isError) {
      this.opponents = [];
      this.page = 1;
      this.totalCount = 0;
    }
    this.loading = false;
  };

  loadOpponentsAffiliates = async (id: string) => {
    this.loading = true;
    const result = await getOpponentsAffiliates({ ...this.getParamsForLoadData(), id: id });
    if (result.isSuccess) {
      this.opponentsAffiliates = [...result.result.items];
      this.totalCount = result.result.pagination.totalCount;
    }
    if (result.isError) {
      this.opponentsAffiliates = [];
      this.page = 1;
      this.totalCount = 0;
    }
    this.loading = false;
  };

  loadOpponentName = async (id: number) => {
    const result = await getOpponent(id);

    if (result.isSuccess) {
      this.opponentName = result.result.name;
    }
  };

  updatePage = (value: number) => {
    if (value === 0) {
      this.page = 1;
    } else {
      this.page = value;
    }
  };

  updateName = (value: string) => {
    this.name = value;
  };

  updateSortParam = (newSortParam: string | null) => {
    this.sortParam = newSortParam;
  };

  updateSortDirection = (newSortDirection: SortOrderType) => {
    this.sortDirection = newSortDirection;
  };

  updateFilters = (newFilters: any): OpponentsFiltersValue => {
    this.loading = true;
    const advertisersIds = getValuesFromFilters(newFilters.advertisersIds) as number[] | string[] | undefined;
    const affiliatesIds = getValuesFromFilters(newFilters.affiliatesIds) as number[] | string[] | undefined;
    const timezone = getValueFromFilters(newFilters.timezone);
    this.filters = {
      timezone,
      dateStart: newFilters.date ? newFilters.date.from : newFilters.dateStart,
      dateEnd: newFilters.date ? newFilters.date.to : newFilters.dateEnd,
    };
    if (advertisersIds && advertisersIds.length) {
      this.filters.advertisersIds = advertisersIds;
    }
    if (affiliatesIds && affiliatesIds.length) {
      this.filters.affiliatesIds = affiliatesIds;
    }
    return this.filters;
  };

  getFilters = (advertisers: DefaultOption[], affiliates: DefaultOption[], timezones: DefaultOption[]): any => {
    const advertisersIds: DefaultOption[] | undefined = getValuesFromOptions(this.filters?.advertisersIds, advertisers);
    const affiliatesIds: DefaultOption[] | undefined = getValuesFromOptions(this.filters?.affiliatesIds, affiliates);
    const timezone = getValueFromOptions(this.filters?.timezone, timezones);
    return {
      advertisersIds,
      affiliatesIds,
      timezone,
      date: {
        from: this.filters?.dateStart,
        to: this.filters?.dateEnd,
      },
    };
  };

  getFiltersParams = () => {
    const paramsObject: Record<string, any> = {};
    if (this.name) {
      paramsObject.name = this.name;
    }
    if (this.sortDirection && this.sortParam) {
      paramsObject.sortDirection = this.sortDirection;
      paramsObject.sortParam = this.sortParam;
    }
    if (this.filters) {
      if (this.filters.advertisersIds && this.filters.advertisersIds.length) {
        paramsObject.advertisersIds = this.filters.advertisersIds;
      }
      if (this.filters.affiliatesIds && this.filters.affiliatesIds.length) {
        paramsObject.affiliatesIds = this.filters.affiliatesIds;
      }
      if (this.filters.dateStart && this.filters.dateEnd) {
        paramsObject.dateStart = this.filters.dateStart;
        paramsObject.dateEnd = this.filters.dateEnd;
      }
      if (this.filters.timezone) {
        paramsObject.timezone = this.filters.timezone;
      }
    }
    return paramsObject;
  };

  getParamsForExport = (hash: string | null) => {
    return { hash, ...this.getFiltersParams() };
  };

  getQueryForPage = (hash: string | null): string => {
    const queryObject: any = {
      hash,
      page: this.page,
    };
    if (this.sortParam && this.sortDirection) {
      queryObject.sortParam = this.sortParam;
      queryObject.sortDirection = this.sortDirection;
    }
    return convertObjectToQueryString(queryObject);
  };

  getParamsForLoadData = (): Record<string, any> => {
    return { page: this.page, pageSize: this.pageSize, ...this.getFiltersParams() };
  };

  getDataForOpponentsTable = () => {
    if (this.opponents) {
      return opponentsForTable(this.opponents);
    }
    return [];
  };

  getDataForOpponentsAffiliatesTable = () => {
    if (this.opponentsAffiliates) {
      return opponentsAffiliatesForTable(this.opponentsAffiliates);
    }
    return [];
  };
}
