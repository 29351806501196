import React, { FC, useEffect, useRef, MouseEventHandler } from "react";

import cn from "classnames";
import { CSSTransition } from "react-transition-group";
import { useEventListener } from "usehooks-ts";

import classes from "./BaseModal.module.scss";
import { BaseModalProps } from "./BaseModal.types";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

export const BaseModal: FC<BaseModalProps> = ({
  children,
  open,
  dialogClassName,
  headerClassName,
  bodyClassName,
  title,
  titleClassName,
  footerClassName,
  targetBtnClassName,
  cancelBtnClassName,
  targetBtnText,
  cancelBtnText,
  showClose = true,
  disabledTarget = false,
  noBodyPadding = false,
  setOpen,
  targetBtnHandler,
  cancelBtnHandler,
}) => {
  const ref = useRef(null);
  const closeModal = () => setOpen && setOpen(false);
  const onClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === ref.current) {
      closeModal();
    }
  };

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      closeModal();
    }
  });
  useEffect(() => {
    if (open) {
      document.body.classList.add(classes.lock);
    } else {
      document.body.classList.remove(classes.lock);
    }
  }, [open]);

  return (
    <CSSTransition
      in={open}
      timeout={500}
      nodeRef={ref}
      classNames={{
        enter: classes["fade-enter"],
        enterActive: classes["fade-enter-active"],
        exit: classes["fade-exit"],
        exitActive: classes["fade-exit-active"],
      }}
      unmountOnExit
    >
      <div ref={ref} data-testid="modal-container" className={classes.container} onClick={onClickHandler}>
        <div data-testid="modal-dialog" className={cn(classes.dialog, dialogClassName)}>
          <div data-testid="modal-content" className={classes.content}>
            <Header
              showClose={showClose}
              setOpen={setOpen}
              title={title}
              titleClassName={titleClassName}
              headerClassName={headerClassName}
            />
            <div
              data-testid="modal-body"
              className={cn(classes.body, noBodyPadding && classes["no-padding"], bodyClassName)}
            >
              {children}
            </div>
            <Footer
              footerClassName={footerClassName}
              targetBtnClassName={targetBtnClassName}
              cancelBtnClassName={cancelBtnClassName}
              targetBtnText={targetBtnText}
              cancelBtnText={cancelBtnText}
              disabledTarget={disabledTarget}
              setOpen={setOpen}
              targetBtnHandler={targetBtnHandler}
              cancelBtnHandler={cancelBtnHandler}
            />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
