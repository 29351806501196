import { FC, ReactNode } from "react";

import { observer, useLocalObservable } from "mobx-react-lite";

import { StatisticsOpponentsStoreContext } from "entities/StatisticsOpponents/context";
import { StatisticsOpponentsStore } from "entities/StatisticsOpponents/store";

export const StatisticOpponentsAffiliatesProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const store = useLocalObservable(() => new StatisticsOpponentsStore("clicksTotal", 3));
  return <StatisticsOpponentsStoreContext.Provider value={store}>{children}</StatisticsOpponentsStoreContext.Provider>;
});
