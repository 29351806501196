import { FC } from "react";

import classes from "./Footer.module.scss";

import { Container } from "shared/ui/Container";
import { Link } from "shared/ui/Link";
import { Logo } from "shared/ui/Logo";

export const Footer: FC = () => {
  return (
    <div className={classes.footer}>
      <Container className={classes.content}>
        <Link to="/dashboard" className={classes.logo}>
          <Logo theme="gray" />
        </Link>
      </Container>
    </div>
  );
};
