// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__Bmbi2 {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
}
@media (max-width: 1400px) {
  .Container_container__Bmbi2 {
    max-width: 1140px;
  }
}
@media (max-width: 1200px) {
  .Container_container__Bmbi2 {
    max-width: 960px;
  }
}
@media (max-width: 992px) {
  .Container_container__Bmbi2 {
    max-width: 720px;
  }
}
@media (max-width: 768px) {
  .Container_container__Bmbi2 {
    max-width: 540px;
  }
}
@media (max-width: 576px) {
  .Container_container__Bmbi2 {
    max-width: none;
  }
}
.Container_container__Bmbi2.Container_fluid__KH98X {
  max-width: none;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Container/Container.module.scss","webpack://./src/shared/assets/helpers/media.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AADF;ACyBE;ED7BF;IAQI,iBAAA;EAAF;AACF;ACcE;EDvBF;IAYI,gBAAA;EACF;AACF;ACGE;EDjBF;IAgBI,gBAAA;EAEF;AACF;ACRE;EDXF;IAoBI,gBAAA;EAGF;AACF;ACnBE;EDLF;IAwBI,eAAA;EAIF;AACF;AAFE;EACE,eAAA;AAIJ","sourcesContent":["@import \"shared/assets/styles\";\n\n.container {\n  width: 100%;\n  max-width: 1320px;\n  margin-left: auto;\n  margin-right: auto;\n  padding: 0 24px;\n\n  @include desktop {\n    max-width: 1140px;\n  }\n\n  @include laptop {\n    max-width: 960px;\n  }\n\n  @include tablet {\n    max-width: 720px;\n  }\n\n  @include small-tablet {\n    max-width: 540px;\n  }\n\n  @include phone {\n    max-width: none;\n  }\n\n  &.fluid {\n    max-width: none;\n  }\n}\n","$sm: 576px;\n$md: 768px;\n$lg: 992px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin phone {\n  @media (max-width: $sm) {\n    @content;\n  }\n}\n\n@mixin small-tablet {\n  @media (max-width: $md) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: $lg) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: $xl) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (max-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__Bmbi2`,
	"fluid": `Container_fluid__KH98X`
};
export default ___CSS_LOADER_EXPORT___;
