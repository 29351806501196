import { FC, ReactNode, useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useMatch } from "react-router-dom";

import { useStore } from "entities/context";

import classes from "./BaseLayout.module.scss";

import { Footer } from "widgets/Footer";
import { Header } from "widgets/Header";

import { Container } from "shared/ui/Container";
import { Loader } from "shared/ui/Loader";

interface BaseLayoutProps {
  children: ReactNode;
}

export const BaseLayout: FC<BaseLayoutProps> = observer(({ children }) => {
  const match = useMatch("/statistics/*");
  const {
    userStore,
    statisticsFiltersStore: { hash, updateHash },
  } = useStore();

  useEffect(() => {
    if (!match && hash) {
      updateHash(null);
    }
  }, [match]);

  return (
    <>
      {userStore.loading ? (
        <Loader icon loading size="large" absolute />
      ) : (
        <>
          <Header />
          <Container className={classes.container}>{children}</Container>
          <Footer />
        </>
      )}
    </>
  );
});
