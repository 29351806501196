import { get } from "../request";

import { convertObjectToQueryString } from "shared/utils";

export const StatisticsOpponentsApi = () => {
  const getOpponents = async (params: Record<string, any>) => {
    const queryParams = convertObjectToQueryString(params);
    return await get(`v1/statistic/opponents${queryParams}`);
  };

  const getOpponentsAffiliates = async (params: Record<string, any>) => {
    const queryParams = convertObjectToQueryString(params);
    return await get(`v1/statistic/opponents-affiliates${queryParams}`);
  };

  const getOpponent = async (id: number) => {
    return await get(`v1/advertiser?id=${id}`);
  };

  return { getOpponents, getOpponentsAffiliates, getOpponent };
};
