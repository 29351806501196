import { makeAutoObservable } from "mobx";

import { OfferProps, TopOfferProps, FilterProps } from "./model";

import { OfferApi } from "shared/api/Offer";
import { OfferParams } from "shared/api/Offer/types";
import { Result } from "shared/api/request";
import { DefaultOption } from "shared/ui/Select";

const { getOffers, getOfferStatuses, getOfferChannels, getOfferSorting, getTopOffers, getTopOffersRating } = OfferApi();

export class OfferStore {
  offers: OfferProps[] | undefined = undefined;
  offerStatuses: FilterProps | undefined = undefined;
  offerChannels: FilterProps | undefined = undefined;
  offerSorts: FilterProps | undefined = undefined;

  totalCount = 0;
  offerFilter: OfferParams = {
    name: "",
    page: 1,
    pageSize: 5,
    statusIds: [],
    channelIds: [],
    sortId: 1,
  };

  topOffers: TopOfferProps[] | undefined = undefined;
  offerRating: DefaultOption[] | undefined = undefined;
  currentRating: number | null = null;

  loading = true;
  tableLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadOffers = async () => {
    this.tableLoading = true;
    const result: Result = await getOffers(this.offerFilter);
    if (result.isSuccess) {
      this.offers = [...result.result.items];
      this.totalCount = result.result.pagination.totalCount;
    }
    this.tableLoading = false;
  };

  loadFilterInfo = async () => {
    if (!this.offerStatuses) {
      const statusResult = await getOfferStatuses();

      if (statusResult.isSuccess) {
        this.offerStatuses = { ...statusResult.result };
      }
    }
    if (!this.offerChannels) {
      const channelResult = await getOfferChannels();

      if (channelResult.isSuccess) {
        this.offerChannels = { ...channelResult.result };
      }
    }
    if (!this.offerSorts) {
      const sortingResult = await getOfferSorting();

      if (sortingResult.isSuccess) {
        this.offerSorts = { ...sortingResult.result };
      }
    }

    this.loading = false;
  };

  loadTopOffersRating = async () => {
    const result: Result = await getTopOffersRating();
    if (result.isSuccess) {
      this.offerRating = [...result.result];
      this.currentRating = result.result[0]?.value || null;
    }
  };

  loadTopOffers = async () => {
    this.tableLoading = true;
    const result: Result = await getTopOffers(this.currentRating);
    if (result.isSuccess) {
      this.topOffers = [...result.result.items];
    }
    this.loading = false;
    this.tableLoading = false;
  };

  updateOfferStatus = (id: number) => {
    const filter = this.offerFilter;

    if (filter.statusIds.includes(id)) {
      this.offerFilter = {
        ...filter,
        statusIds: [...filter.statusIds.filter((val) => val !== id)],
      };
    } else {
      this.offerFilter = {
        ...filter,
        statusIds: [...filter.statusIds, id],
      };
    }
  };

  updateOfferChannel = (id: number) => {
    const filter = this.offerFilter;

    if (filter.channelIds.includes(id)) {
      this.offerFilter = {
        ...filter,
        channelIds: [...filter.channelIds.filter((val) => val !== id)],
      };
    } else {
      this.offerFilter = {
        ...filter,
        channelIds: [...filter.channelIds, id],
      };
    }
  };

  updateSortingId = (id: number) => {
    const filter = this.offerFilter;
    this.offerFilter = {
      ...filter,
      sortId: id,
    };
  };

  updateSearchParam = (value: string) => {
    const filter = this.offerFilter;
    this.offerFilter = {
      ...filter,
      name: value,
    };
  };

  updatePage = (value: number) => {
    const filter = this.offerFilter;
    this.offerFilter = {
      ...filter,
      page: value,
    };
  };

  updatePageSize = (value: number) => {
    const filter = this.offerFilter;
    this.offerFilter = {
      ...filter,
      pageSize: value,
    };
  };

  updateOfferRating = (value: number) => {
    this.currentRating = value;
  };
}
