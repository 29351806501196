import { FC, useState } from "react";

import { DndContext, DragOverlay, pointerWithin } from "@dnd-kit/core";

import { useStore } from "entities/context";

import { Column } from "./components/Column";
import { SortableItem } from "./components/SortableItem";
import classes from "./Dnd.module.scss";
import { DnDProps, ItemProps } from "./DnD.types";
import { handleDragOverGrid, handleDragStart } from "./utils";

import { DashboardSettings } from "widgets/DashboardSettings";

import { Col, Grid } from "shared/ui/Grid";

export const DashboardDnD: FC<DnDProps> = ({ items, setItems }) => {
  const [activeItem, setActiveItem] = useState<ItemProps | null>(null);
  const {
    dashboardStore: { updateLayout },
  } = useStore();

  return (
    <DndContext
      collisionDetection={pointerWithin}
      onDragStart={(e) => handleDragStart(e, setActiveItem)}
      onDragOver={(e) => handleDragOverGrid(e, items, setItems)}
      onDragEnd={() => {
        setActiveItem(null);
        updateLayout(items);
      }}
    >
      <Grid justify="between" lg={{ direction: "column-reverse" }}>
        <Col span={4} lg={{ span: 12 }}>
          <Column id={"0"} key={"0"} items={items.filter((item) => item.column === 0)} />
        </Col>
        <Col span={4} lg={{ span: 12 }}>
          <Column id={"1"} key={"1"} items={items.filter((item) => item.column === 1)} />
        </Col>
        <Col span={4} lg={{ span: 12 }}>
          <DashboardSettings />
          <Column id={"2"} key={"2"} items={items.filter((item) => item.column === 2)} />
        </Col>
      </Grid>
      <DragOverlay>
        {activeItem && <SortableItem item={activeItem} grabbing className={classes["dragging-item"]} />}
      </DragOverlay>
    </DndContext>
  );
};
