import { CSSProperties, FC } from "react";

import cn from "classnames";

import classes from "./Skeleton.module.scss";
import { SkeletonProps } from "./Skeleton.types";

export const Skeleton: FC<SkeletonProps> = ({ className, columns = 12, rows = 1, active = true }) => {
  const style = {
    "--skeleton-rows": rows,
    "--skeleton-columns": columns,
  } as CSSProperties;
  return <div className={cn(classes.skeleton, active && classes.active, className)} style={style} />;
};
