import { Route, createRoutesFromElements } from "react-router-dom";
import uniqid from "uniqid";

import { ProfileLayout } from "shared/layouts/ProfileLayout";

export const profileRoutes = createRoutesFromElements(
  <Route id={uniqid()} element={<ProfileLayout />}>
    <Route
      id={uniqid()}
      key={uniqid()}
      path={"/profile"}
      lazy={async () => {
        const { ProfilePage } = await import("pages/ProfilePage");
        return { Component: ProfilePage };
      }}
    />
    <Route
      id={uniqid()}
      key={uniqid()}
      path={"/profile/history"}
      lazy={async () => {
        const { ExportHistoryPage } = await import("pages/ExportHistoryPage");
        return { Component: ExportHistoryPage };
      }}
    />
  </Route>,
);
