// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_container__zbjpd {
  position: relative;
  border-radius: 12px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Card/Card.module.scss","webpack://./src/shared/assets/helpers/colors.scss","webpack://./src/shared/assets/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBCWM;EDVN,yCEHO;AFET","sourcesContent":["@import \"shared/assets/styles\";\n\n.container {\n  position: relative;\n  border-radius: 12px;\n  padding: 16px;\n  background-color: $white;\n  box-shadow: $shadow;\n}\n","$orange-yellow: #ffb600;\n$orange-yellow-hover: #ffc533;\n$orange-yellow-active: #f4af02;\n$orange-yellow-disabled: #fff0cc;\n$orange-yellow-10: #fff8e6;\n$orange: #ff8a00;\n$orange-10: #fff3e6;\n$black: #242424;\n$black-active: #000;\n$gray-80: #505050;\n$gray-60: #7c7c7c;\n$gray-40: #a7a7a7;\n$gray-30: #bdbdbd;\n$gray-20: #d3d3d3;\n$gray-10: #e9e9e9;\n$gray-5: #f4f4f4;\n$gray-background: #f9f9f9;\n$white: #fff;\n$red: #ff3a3a;\n$red-10: #ffebeb;\n$green: #3bd520;\n$green-disabled: #c4f2bc;\n$green-10: #ebfbe9;\n$yellow: #ffdc22;\n$yellow-10: #fffbe9;\n$blue: #00b2ff;\n$blue-active: #00a0e6;\n$blue-hover: #33c2ff;\n$blue-disabled: #ccf0ff;\n$blue-10: #e6f7ff;\n$dark-blue: #06f;\n$dark-blue-10: #e6f0ff;\n$purple: #a500f2;\n$purple-10: #f6e6fe;\n$pink: #ff4ced;\n$pink-10: #ffedfd;\n","@import \"./helpers/colors\";\n@import \"./helpers/fonts\";\n@import \"./helpers/media\";\n\n$shadow: 0 0 15px 0 rgb(0 0 0 / 10%);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Card_container__zbjpd`
};
export default ___CSS_LOADER_EXPORT___;
