import { FC, useRef, useEffect, useState } from "react";

import { ReactComponent as SuccessIcon } from "@material-symbols/svg-400/rounded/check_circle-fill.svg";
import { ReactComponent as Close } from "@material-symbols/svg-400/rounded/close.svg";
import { ReactComponent as WarningIcon } from "@material-symbols/svg-400/rounded/error-fill.svg";
import { ReactComponent as InfoIcon } from "@material-symbols/svg-400/rounded/info-fill.svg";
import { ReactComponent as ErrorIcon } from "@material-symbols/svg-400/rounded/report-fill.svg";
import cn from "classnames";
import { CSSTransition } from "react-transition-group";

import { BaseButton } from "../BaseButton";
import { Text } from "../Text";

import classes from "./Notification.module.scss";
import { NotificationProps, NotificationType } from "./Notification.types";

const getNotificationTypeIcon = (type: NotificationType) => {
  switch (type) {
    case "success":
      return <SuccessIcon />;
    case "warning":
      return <WarningIcon />;
    case "error":
      return <ErrorIcon />;
    case "info":
      return <InfoIcon />;
    default:
      break;
  }
};

export const Notification: FC<NotificationProps> = ({
  id,
  message,
  type,
  className,
  delay = 5000,
  closeOnClick = false,
  removeNotification,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (open && !closeOnClick) {
      setTimeout(() => {
        setOpen(false);
      }, delay);
    }
  }, [open, closeOnClick]);

  return (
    <CSSTransition
      in={open}
      key={id}
      timeout={500}
      nodeRef={ref}
      classNames={{
        enter: classes[`slide-enter`],
        enterActive: classes[`slide-enter-active`],
        exit: classes[`slide-exit`],
        exitActive: classes[`slide-exit-active`],
      }}
      onExited={() => {
        if (id) {
          removeNotification && removeNotification(id);
        }
      }}
      unmountOnExit
    >
      <div ref={ref} className={cn(classes.notification, closeOnClick && classes["notification-close"], className)}>
        <div className={cn(classes.icon, classes[type])}>{getNotificationTypeIcon(type)}</div>
        <Text className={classes.message} size="lg">
          {message}
        </Text>
        {closeOnClick && (
          <BaseButton
            className={classes.close}
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </BaseButton>
        )}
      </div>
    </CSSTransition>
  );
};
