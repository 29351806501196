import { ReactComponent as ArrowIcon } from "@material-symbols/svg-400/rounded/arrow_forward_ios.svg";

import { OpponentAffiliatesProps, OpponentProps } from "./model";
import classes from "./styles/Statistics.module.scss";

import { Link } from "shared/ui/Link";
import { formatNumber } from "shared/utils";

export const opponentsForTable = (opponents: OpponentProps[]) => {
  return opponents.map((opponent, i) => ({
    key: `opponent-${opponent.id}-${i}`,
    name: (
      <Link to={`/statistics/opponents/${opponent.id}`} className={classes.link} variant="black">
        {opponent.name}
        <ArrowIcon className={classes.icon} />
      </Link>
    ),
    id: opponent.id,
    affiliatesTotal: formatNumber(opponent.affiliatesTotal),
    cr: formatNumber(opponent.cr),
    ar: formatNumber(opponent.ar),
    epc: formatNumber(opponent.epc),
  }));
};

export const opponentsAffiliatesForTable = (opponents: OpponentAffiliatesProps[]) => {
  return opponents.map((opponent, i) => ({
    id: opponent.id,
    key: `opponent-affiliate-${opponent.id}-${i}`,
    clicksTotal: formatNumber(opponent.clicksTotal),
  }));
};
