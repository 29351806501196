// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BurgerButton_burger__5mSvT {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BurgerButton_burger__5mSvT span {
  font-size: 24px;
  flex-shrink: 0;
}
.BurgerButton_burger__5mSvT span.BurgerButton_close__O33a7 {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Header/components/BurgerButton/BurgerButton.module.scss","webpack://./src/shared/assets/helpers/colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,eAAA;EACA,cAAA;AADJ;AAGI;EACE,WCIE;ADLR","sourcesContent":["@import \"shared/assets/styles\";\r\n\r\n.burger {\r\n  cursor: pointer;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n\r\n  span {\r\n    font-size: 24px;\r\n    flex-shrink: 0;\r\n\r\n    &.close {\r\n      color: $white;\r\n    }\r\n  }\r\n}\r\n","$orange-yellow: #ffb600;\n$orange-yellow-hover: #ffc533;\n$orange-yellow-active: #f4af02;\n$orange-yellow-disabled: #fff0cc;\n$orange-yellow-10: #fff8e6;\n$orange: #ff8a00;\n$orange-10: #fff3e6;\n$black: #242424;\n$black-active: #000;\n$gray-80: #505050;\n$gray-60: #7c7c7c;\n$gray-40: #a7a7a7;\n$gray-30: #bdbdbd;\n$gray-20: #d3d3d3;\n$gray-10: #e9e9e9;\n$gray-5: #f4f4f4;\n$gray-background: #f9f9f9;\n$white: #fff;\n$red: #ff3a3a;\n$red-10: #ffebeb;\n$green: #3bd520;\n$green-disabled: #c4f2bc;\n$green-10: #ebfbe9;\n$yellow: #ffdc22;\n$yellow-10: #fffbe9;\n$blue: #00b2ff;\n$blue-active: #00a0e6;\n$blue-hover: #33c2ff;\n$blue-disabled: #ccf0ff;\n$blue-10: #e6f7ff;\n$dark-blue: #06f;\n$dark-blue-10: #e6f0ff;\n$purple: #a500f2;\n$purple-10: #f6e6fe;\n$pink: #ff4ced;\n$pink-10: #ffedfd;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"burger": `BurgerButton_burger__5mSvT`,
	"close": `BurgerButton_close__O33a7`
};
export default ___CSS_LOADER_EXPORT___;
