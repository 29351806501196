type List = Record<string, string>;

export const currenciesSymbols: List = {
  RUB: "₽",
  USD: "$",
  EUR: "€",
  UAH: "₴",
  KZT: "₸",
  PHP: "php",
  MXN: "mxn",
};
export const currenciesLabels: List = {
  RUB: "руб.",
  USD: "$",
  EUR: "€",
  UAH: "грн.",
  KZT: "тг.",
  PHP: "фп.",
  MXN: "мп.",
};

export const getCurrencySymbol = (code: string): string => {
  return currenciesSymbols[code] || code;
};
export const getCurrencyLabel = (code: string): string => {
  return currenciesLabels[code] || code;
};
