import { FC, ReactNode } from "react";

import cn from "classnames";
import { Tooltip as TooltipContent, PlacesType } from "react-tooltip";
import uniqid from "uniqid";

import { Arrow } from "./components/Arrow";
import classes from "./Tooltip.module.scss";

import { BasePortal } from "shared/ui/BasePortal";

interface Props {
  /** Класс для кастомизации тултипа */
  className?: string;
  /** Класс для кастомизации цели */
  targetClassName?: string;
  /** Контент на котороый вешаем тултип */
  children: ReactNode;
  /** Контент который отображаем при наведении */
  content: ReactNode;
  /** Цветовая тема тултипа */
  theme?: "light" | "dark";
  /** Расположение тултипа отностительно children */
  place?: PlacesType;
  /** Показывает/скрывает тултип */
  open?: boolean;
  /** Открыть по клику */
  openOnClick?: boolean;
  /** Задержка перед закрытием тултипа, ms */
  delayHide?: number;
}

const portalNode = document.createElement("div");
portalNode.setAttribute("id", "tooltip-portal");
portalNode.setAttribute("data-testid", "tooltip-portal");

/**
 * Для стилизации тултипа, можно использовать пропы кастомных классов:
 * - `className` -- стилизация тултипа
 * - `targetClassName` -- стилизация элемента цели
 */
export const Tooltip: FC<Props> = ({
  className,
  targetClassName,
  children,
  content,
  theme = "light",
  place = "top",
  open,
  openOnClick,
  delayHide = 0,
}) => {
  const id = uniqid("tooltip-");

  return (
    <>
      <span className={cn(targetClassName, classes.target)} data-testid="target-tooltip-content" data-tooltip-id={id}>
        {children}
      </span>
      <BasePortal portalNode={portalNode}>
        <TooltipContent
          id={id}
          disableStyleInjection
          openOnClick={openOnClick}
          noArrow
          delayHide={delayHide}
          offset={6}
          place={place}
          isOpen={open}
          className={cn(classes.tooltip, classes[`tooltip-${theme}`], className)}
        >
          {content}
          <Arrow place={place} theme={theme} />
        </TooltipContent>
      </BasePortal>
    </>
  );
};
