import { FC } from "react";

import { ReactComponent as DefaultPhoto } from "@material-symbols/svg-400/rounded/ar_on_you.svg";

import classes from "./Photo.module.scss";

import { Image } from "shared/ui/Image";

interface PhotoProps {
  url?: string;
}

export const Photo: FC<PhotoProps> = ({ url }) => {
  return (
    <div className={classes.container}>
      {url ? <Image src={url} className={classes.photo} /> : <DefaultPhoto className={classes.icon} />}
    </div>
  );
};
