import { FC, useState, useEffect, useRef } from "react";

import { ReactComponent as CopyIcon } from "@material-symbols/svg-400/rounded/content_copy.svg";
import cn from "classnames";

import { MangerProps } from "entities/Manager/model";

import classes from "./Info.module.scss";

import { BaseButton } from "shared/ui/BaseButton";
import { Link } from "shared/ui/Link";
import { Text } from "shared/ui/Text";
import { Tooltip } from "shared/ui/Tooltip";

export const Info: FC<Omit<MangerProps, "imageUrl" | "gender">> = ({
  phone,
  email,
  skype,
  whatsapp,
  telegram,
  lastName,
  firstName,
  middleName,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open]);

  const handleCopy = () => {
    if (email) {
      navigator.clipboard.writeText(email);
      setOpen(true);
    }
  };

  return (
    <div className={classes.info}>
      {(lastName || firstName || middleName) && (
        <div className={classes.field}>
          <Text size="lg" height="normal" className={classes.name}>
            ФИО
          </Text>
          <Text size="lg" height="normal" className={cn(classes.value, classes["full-name"])}>
            {lastName + " " + firstName + " " + middleName}
          </Text>
        </div>
      )}
      {phone && (
        <div className={classes.field}>
          <Text size="lg" height="normal" className={classes.name}>
            Телефон
          </Text>
          <Text size="lg" height="normal" className={classes.value}>
            {phone}
          </Text>
        </div>
      )}
      {email && (
        <div className={classes.field}>
          <Text size="lg" height="normal" className={classes.name}>
            E-mail
          </Text>
          <BaseButton onClick={handleCopy} className={cn(classes["email-container"])}>
            <Text size="lg" height="normal" className={classes.value}>
              <Tooltip
                theme="dark"
                targetClassName={cn(classes.email)}
                place="top-start"
                content={<Text inline>Скопировано</Text>}
                open={open}
              >
                {email}
              </Tooltip>
            </Text>
            <CopyIcon className={classes.copy} />
          </BaseButton>
        </div>
      )}
      {skype && (
        <div className={classes.field}>
          <Text size="lg" height="normal" className={classes.name}>
            Skype
          </Text>
          <Text size="lg" height="normal" className={classes.value}>
            <Link target="_blank" to={`skype:${skype}?chat`}>
              {skype}
            </Link>
          </Text>
        </div>
      )}
      {telegram && (
        <div className={classes.field}>
          <Text size="lg" height="normal" className={classes.name}>
            Telegram
          </Text>
          <Text size="lg" height="normal" className={classes.value}>
            <Link target="_blank" to={`https://t.me/${telegram.replace("@", "")}`}>
              {telegram}
            </Link>
          </Text>
        </div>
      )}
      {whatsapp && (
        <div className={classes.field}>
          <Text size="lg" height="normal" className={classes.name}>
            WhatsApp
          </Text>
          <Text size="lg" height="normal" className={classes.value}>
            <Link target="_blank" to={`https://wa.me/${whatsapp}`}>
              Написать
            </Link>
          </Text>
        </div>
      )}
    </div>
  );
};
