import { FC } from "react";

import { Outlet } from "react-router-dom";

import { BaseLayout } from "../BaseLayout";

export const MainLayout: FC = () => {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};
