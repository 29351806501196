import { CSSProperties, FC } from "react";

import { ReactComponent as Arrow } from "@material-symbols/svg-400/rounded/arrow_forward_ios.svg";
import { ReactComponent as Cancel } from "@material-symbols/svg-400/rounded/cancel.svg";
import { ReactComponent as Check } from "@material-symbols/svg-400/rounded/check.svg";
import { ReactComponent as Close } from "@material-symbols/svg-400/rounded/close.svg";
import cn from "classnames";
import {
  DropdownIndicatorProps,
  components,
  ClearIndicatorProps,
  ValueContainerProps,
  OptionProps,
  GroupProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from "react-select";

import classes from "../Select.module.scss";

export const DropdownIndicator: FC<DropdownIndicatorProps> = ({ selectProps }) => (
  <div className={cn(classes.indicator, classes.dropdown, selectProps.menuIsOpen && classes.open)}>
    <Arrow />
  </div>
);

export const ClearIndicator: FC<ClearIndicatorProps> = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      data-testid="clear-button"
      style={getStyles("clearIndicator", props) as CSSProperties}
      className={classes.clear}
    >
      <Cancel />
    </div>
  );
};

export const ValueContainer: FC<ValueContainerProps> = (props) => {
  if (Array.isArray(props.children)) {
    const length = props?.children[0]?.length || 0;

    return (
      <components.ValueContainer {...props}>
        {length !== 0 ? <>Выбрано: {length}</> : props?.children[0]}
        {props?.children[1]}
      </components.ValueContainer>
    );
  }
  return null;
};

export const TagValueContainer: FC<ValueContainerProps> = (props) => <components.ValueContainer {...props} />;

export const Option: FC<OptionProps> = (props) => {
  return (
    <div className={classes["option-container"]}>
      <components.Option {...props} />
      {props.isSelected && <Check />}
    </div>
  );
};

export const Group: FC<GroupProps> = (props) => {
  return (
    <div className={classes.group}>
      <components.Group {...props} />
    </div>
  );
};

export const FormatGroupLabel: FC<any> = (data) => <span className={classes["group-label"]}>{data.label}</span>;

export const NoOptionsMessage: FC = () => <p className={classes["not-found"]}>0 результатов</p>;

export const MultiValueContainer: FC<MultiValueGenericProps & { size: "small" | "normal" | "big" }> = (props) => {
  return (
    <div className={cn(classes["multi-value-container"], classes[`multi-value-container-${props.size}`])}>
      <components.MultiValueContainer {...props} />
    </div>
  );
};

export const MultiValueRemoveIndicator: FC<MultiValueRemoveProps & { size: "small" | "normal" | "big" }> = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <div role="button" className={cn(classes["remove-indicator"], classes[`remove-indicator-${props.size}`])}>
        <Close />
      </div>
    </components.MultiValueRemove>
  );
};
