import { FC, useState } from "react";

import cn from "classnames";

import classes from "../Table.module.scss";
import { TableBodyCellContentProps, TableBodyCellProps } from "../Table.types";

import { BaseButton } from "shared/ui/BaseButton";
import { Checkbox } from "shared/ui/Checkbox";

export const TableBodyCell: FC<TableBodyCellProps> = ({
  align,
  children,
  width,
  id,
  href,
  rowSelection,
  isCheckbox,
  isSummary,
  colSpan = 1,
  rowSpan = 1,
  ellipsis = false,
  onCellClick,
}) => {
  return (
    <td
      data-testid="table-body-cell-test"
      className={cn(classes["table-body-cell"], classes[`table-body-cell-${align}`], isSummary && classes.summary)}
      style={{ width: width }}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      <TableBodyCellContent
        href={href}
        onCellClick={onCellClick}
        rowSelection={rowSelection}
        width={width}
        id={id}
        isSummary={isSummary}
        isCheckbox={isCheckbox}
        ellipsis={ellipsis}
      >
        {children}
      </TableBodyCellContent>
    </td>
  );
};

export const TableBodyCellContent: FC<TableBodyCellContentProps> = ({
  children,
  href,
  id,
  rowSelection,
  ellipsis,
  width,
  isCheckbox = false,
  isSummary = false,
  onCellClick,
}) => {
  if (isCheckbox && rowSelection) {
    const { selectedIds, onRowSelect } = rowSelection;
    const [checked, setChecked] = useState<boolean>(selectedIds.includes(id ?? "0"));

    const handleChange = () => {
      if (id !== undefined && !selectedIds.includes(id)) {
        onRowSelect([...selectedIds, id]);
      } else {
        onRowSelect([...selectedIds.filter((key) => key !== id)]);
      }
      setChecked(!checked);
    };

    return <Checkbox className={classes["table-body-cell-content"]} checked={checked} onChange={handleChange} />;
  }

  if (onCellClick || href) {
    return (
      <BaseButton
        className={cn(
          classes["table-body-cell-content"],
          classes["button"],
          isSummary && classes.summary,
          ellipsis && classes.ellipsis,
        )}
        href={href}
        onClick={onCellClick}
      >
        {children}
      </BaseButton>
    );
  }
  let styles: any;
  if (ellipsis)
    styles = { width: width, display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" };

  return (
    <div style={{ ...styles }} className={cn(classes["table-body-cell-content"], ellipsis && classes.ellipsis)}>
      {isSummary && rowSelection ? "Итого" : children}
    </div>
  );
};
