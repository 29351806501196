// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardManager_manager__KGi-G {
  position: relative;
  min-height: 94px;
  display: flex;
  align-items: center;
  gap: 24px;
}
@media (max-width: 1400px) {
  .DashboardManager_manager__KGi-G {
    flex-direction: column;
  }
}
@media (max-width: 992px) {
  .DashboardManager_manager__KGi-G {
    flex-direction: row;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/DashboardManager/DashboardManager.module.scss","webpack://./src/shared/assets/helpers/media.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AADF;ACyBE;ED7BF;IAQI,sBAAA;EAAF;AACF;ACQE;EDjBF;IAYI,mBAAA;EACF;AACF","sourcesContent":["@import \"shared/assets/styles\";\n\n.manager {\n  position: relative;\n  min-height: 94px;\n  display: flex;\n  align-items: center;\n  gap: 24px;\n\n  @include desktop {\n    flex-direction: column;\n  }\n\n  @include tablet {\n    flex-direction: row;\n  }\n}\n","$sm: 576px;\n$md: 768px;\n$lg: 992px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin phone {\n  @media (max-width: $sm) {\n    @content;\n  }\n}\n\n@mixin small-tablet {\n  @media (max-width: $md) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: $lg) {\n    @content;\n  }\n}\n\n@mixin laptop {\n  @media (max-width: $xl) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (max-width: $xxl) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manager": `DashboardManager_manager__KGi-G`
};
export default ___CSS_LOADER_EXPORT___;
