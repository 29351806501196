import { makeAutoObservable } from "mobx";

import { TopAffiliatesProps } from "./model";

import { AffiliateApi } from "shared/api/Affiliate";
import { Result } from "shared/api/request";
import { DefaultOption } from "shared/ui/Select";
import { formatNumber } from "shared/utils";

const { getTopAffiliates, getTopAffiliatesRating } = AffiliateApi();

export class AffiliateStore {
  affiliates: TopAffiliatesProps[] | undefined = undefined;
  affiliateRating: DefaultOption[] | undefined = undefined;
  currentRating: number | null = null;
  loading = true;
  tableLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  getTopAffiliates = () => {
    if (this.affiliates) {
      return this.affiliates.map((affiliate, i) => {
        return {
          ...affiliate,
          key: `top-affiliate-${affiliate.id}-${i}`,
          ar: formatNumber(affiliate.ar),
          cr: formatNumber(affiliate.cr),
          epc: formatNumber(affiliate.epc),
        };
      });
    }
    return [];
  };

  loadTopAffiliatesRating = async () => {
    const result: Result = await getTopAffiliatesRating();
    if (result.isSuccess) {
      this.affiliateRating = [...result.result];
      this.currentRating = result.result[0]?.value || null;
    }
  };

  loadTopAffiliates = async () => {
    this.tableLoading = true;
    const result: Result = await getTopAffiliates(this.currentRating);
    if (result.isSuccess) {
      this.affiliates = [...result.result.items];
    }
    this.loading = false;
    this.tableLoading = false;
  };

  updateAffiliateRating = (value: number) => {
    this.currentRating = value;
  };
}
