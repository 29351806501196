import { FC, ReactNode } from "react";

import cn from "classnames";
import { NavLink as RouterLink } from "react-router-dom";

import classes from "./NavLink.module.scss";

interface Props {
  to: string;
  isSideMenu?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const NavLink: FC<Props> = ({ to, className, children, isSideMenu = false, onClick }) => {
  return (
    <RouterLink
      className={({ isActive }) => {
        return cn(classes.link, isSideMenu && classes.profile, isActive && classes.active, className);
      }}
      end={isSideMenu}
      to={to}
      onClick={onClick}
    >
      {children}
    </RouterLink>
  );
};
