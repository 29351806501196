// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardOffers_offers__3wctJ {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.DashboardOffers_offers__3wctJ .DashboardOffers_text__thjIx {
  padding: 16px 0;
}

.DashboardOffers_loader__nh2Qd {
  min-height: 94px;
}

.DashboardOffers_logo__BLbNn {
  width: 68px;
  height: 24px;
  object-fit: contain;
  object-position: left center;
}

.DashboardOffers_icon__y7YKd {
  width: 24px;
  height: 24px;
  fill: #bdbdbd;
}`, "",{"version":3,"sources":["webpack://./src/widgets/DashboardOffers/DashboardOffers.module.scss","webpack://./src/shared/assets/helpers/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAGE;EACE,eAAA;AADJ;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,4BAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,aCdQ;ADYV","sourcesContent":["@import \"shared/assets/styles\";\n\n.offers {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  .text {\n    padding: 16px 0;\n  }\n}\n\n.loader {\n  min-height: 94px;\n}\n\n.logo {\n  width: 68px;\n  height: 24px;\n  object-fit: contain;\n  object-position: left center;\n}\n\n.icon {\n  width: 24px;\n  height: 24px;\n  fill: $gray-30;\n}\n","$orange-yellow: #ffb600;\n$orange-yellow-hover: #ffc533;\n$orange-yellow-active: #f4af02;\n$orange-yellow-disabled: #fff0cc;\n$orange-yellow-10: #fff8e6;\n$orange: #ff8a00;\n$orange-10: #fff3e6;\n$black: #242424;\n$black-active: #000;\n$gray-80: #505050;\n$gray-60: #7c7c7c;\n$gray-40: #a7a7a7;\n$gray-30: #bdbdbd;\n$gray-20: #d3d3d3;\n$gray-10: #e9e9e9;\n$gray-5: #f4f4f4;\n$gray-background: #f9f9f9;\n$white: #fff;\n$red: #ff3a3a;\n$red-10: #ffebeb;\n$green: #3bd520;\n$green-disabled: #c4f2bc;\n$green-10: #ebfbe9;\n$yellow: #ffdc22;\n$yellow-10: #fffbe9;\n$blue: #00b2ff;\n$blue-active: #00a0e6;\n$blue-hover: #33c2ff;\n$blue-disabled: #ccf0ff;\n$blue-10: #e6f7ff;\n$dark-blue: #06f;\n$dark-blue-10: #e6f0ff;\n$purple: #a500f2;\n$purple-10: #f6e6fe;\n$pink: #ff4ced;\n$pink-10: #ffedfd;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offers": `DashboardOffers_offers__3wctJ`,
	"text": `DashboardOffers_text__thjIx`,
	"loader": `DashboardOffers_loader__nh2Qd`,
	"logo": `DashboardOffers_logo__BLbNn`,
	"icon": `DashboardOffers_icon__y7YKd`
};
export default ___CSS_LOADER_EXPORT___;
