import { DragOverEvent, DragStartEvent, UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { ColumnProps, ItemFuncType, ItemProps, ItemsFuncType } from "./DnD.types";

const getItem = (item: ItemProps[], id: UniqueIdentifier): number => {
  return item.findIndex((t) => t.id === id);
};

export function handleDragOverGrid(event: DragOverEvent, items: any, setItems: (val: any) => void) {
  const { active, over } = event;
  if (!over) return;

  const activeId = active.id;
  const overId = over.id;

  if (activeId === overId) return;

  const isActiveATask = active.data.current?.type === "Item";
  const isOverATask = over.data.current?.type === "Item";

  if (!isActiveATask) return;

  if (isActiveATask && isOverATask) {
    setItems((item: any) => {
      const activeIndex = getItem(items, activeId);
      const overIndex = getItem(items, overId);

      if (item[activeIndex].column != item[overIndex].column) {
        item[activeIndex].column = item[overIndex].column;
        return arrayMove(item, activeIndex, overIndex - 1);
      }

      return arrayMove(item, activeIndex, overIndex);
    });
  }

  const isOverAColumn = over.data.current?.type === "Column";

  if (isActiveATask && isOverAColumn) {
    setItems((item: any) => {
      const activeIndex = getItem(items, activeId);
      item[activeIndex].column = Number(overId);
      return arrayMove(item, activeIndex, activeIndex);
    });
  }
}

export function handleDragOverColumn(
  event: DragOverEvent,
  items: ItemProps[],
  setItemsToShow: (val: ItemsFuncType) => void,
) {
  const { active, over } = event;
  if (!over) return;

  const activeId = active.id;
  const overId = over.id;

  if (activeId === overId) return;

  const isActiveATask = active.data.current?.type === "Item";
  const isOverATask = over.data.current?.type === "Item";

  if (!isActiveATask) return;

  if (isActiveATask && isOverATask) {
    setItemsToShow((item) => {
      const activeIndex = getItem(items, activeId);
      const overIndex = getItem(items, overId);

      return arrayMove(item, activeIndex, overIndex);
    });
  }
}

export function handleDragStart(event: DragStartEvent, setActiveItem: (val: ItemFuncType) => void) {
  const active = event.active.data.current;

  if (active?.type === "Item" && active?.data.isDraggable) {
    setActiveItem(active?.data);
  }
}

export function setupGridColumns(items: ItemProps[], setColumns: (val: ColumnProps) => void) {
  let newColumns: ColumnProps = [];
  items.forEach((item: ItemProps) => {
    if (item.column) {
      if (!newColumns.includes(item.column)) {
        newColumns = [...newColumns, item.column];
      }
    }
  });
  setColumns(newColumns);
}
