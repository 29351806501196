import { FC, ReactNode } from "react";

import { ReactComponent as Close } from "@material-symbols/svg-400/rounded/close.svg";
import cn from "classnames";

import classes from "./BurgerButton.module.scss";

export interface BurgerButtonProps {
  children?: ReactNode;
  className?: string;
  open?: boolean;
  setOpen?: (val: boolean) => void;
}

export const BurgerButton: FC<BurgerButtonProps> = ({ children, className, open = false, setOpen }) => {
  return (
    <button
      type="button"
      data-testid="burger-button-test"
      onClick={() => setOpen && setOpen(!open)}
      className={cn(classes.burger, className)}
    >
      {open ? <Close /> : children}
    </button>
  );
};
