// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardSettings_settings__QEdjE {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.DashboardSettings_modal__HQH7r {
  width: 320px;
}

.DashboardSettings_modal-body__c0X5S {
  padding: 24px 0;
}`, "",{"version":3,"sources":["webpack://./src/widgets/DashboardSettings/DashboardSettings.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".settings {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.modal {\r\n  width: 320px;\r\n}\r\n\r\n.modal-body {\r\n  padding: 24px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `DashboardSettings_settings__QEdjE`,
	"modal": `DashboardSettings_modal__HQH7r`,
	"modal-body": `DashboardSettings_modal-body__c0X5S`
};
export default ___CSS_LOADER_EXPORT___;
