import axios, { AxiosResponse } from "axios";

const BASE_URL = process.env.REACT_APP_AUTH_URL;

export enum ResponseStatuses {
  success = "success",
  error = "error",
}

export interface Response {
  status: ResponseStatuses.success | ResponseStatuses.error;
  result?: any;
  error?: any;
  data?: any;
  message?: any;
}

interface ResponseDataProps extends Omit<AxiosResponse, "data"> {
  data: Response;
}

export interface Result extends Omit<Response, "status" | "data" | "message"> {
  isSuccess: boolean;
  isError: boolean;
}

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
    "Access-Control-Allow-Origin": "*",
  },
});
axiosInstance.defaults.withCredentials = true;

const successHandler = (response: ResponseDataProps): Result => {
  const data = response.data;
  if (data.status === ResponseStatuses.success) {
    return {
      isSuccess: true,
      isError: false,
      result: data.result ? data.result : null,
    };
  } else {
    return {
      isSuccess: false,
      isError: true,
      error: data.data ? data.data : null,
    };
  }
};
const errorHandler = (error: any): Result => {
  if (error.request?.status === 403) {
    location.replace(process.env.REACT_APP_PUBLIC_URL as string);
  }
  return { isSuccess: false, isError: true };
};

export const post = async (method: string, data: any) => {
  return await axiosInstance.post(`/${method}`, data).then(successHandler).catch(errorHandler);
};

export const get = async (method: string) => {
  return await axiosInstance.get(`/${method}`).then(successHandler).catch(errorHandler);
};
