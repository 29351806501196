// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Link_link__-xO8v {
  transition: color 0.3s;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: #ffb600;
  text-decoration: underline;
}

.Link_blue__uFaxN {
  color: #00b2ff;
}
.Link_blue__uFaxN:hover {
  color: #33c2ff;
}
.Link_blue__uFaxN:active, .Link_blue__uFaxN:focus, .Link_blue__uFaxN:active:focus {
  color: #00a0e6;
}

.Link_black__t3bPl {
  color: #242424;
}
.Link_black__t3bPl:hover {
  color: #505050;
}
.Link_black__t3bPl:active, .Link_black__t3bPl:focus, .Link_black__t3bPl:active:focus {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Link/Link.module.scss","webpack://./src/shared/assets/helpers/colors.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,kBAAA;EACA,oBAAA;EACA,oBAAA;EACA,cCPc;EDQd,0BAAA;AADF;;AAIA;EACE,cCaK;ADdP;AAGE;EACE,cCYS;ADbb;AAIE;EAGE,cCKU;ADTd;;AAQA;EACE,cCnBM;ADcR;AAOE;EACE,cCpBM;ADeV;AAQE;EAGE,WC3BW;ADmBf","sourcesContent":["@import \"shared/assets/styles\";\n\n.link {\n  transition: color 0.3s;\n  font-size: inherit;\n  font-weight: inherit;\n  line-height: inherit;\n  color: $orange-yellow;\n  text-decoration: underline;\n}\n\n.blue {\n  color: $blue;\n\n  &:hover {\n    color: $blue-hover;\n  }\n\n  &:active,\n  &:focus,\n  &:active:focus {\n    color: $blue-active;\n  }\n}\n\n.black {\n  color: $black;\n\n  &:hover {\n    color: $gray-80;\n  }\n\n  &:active,\n  &:focus,\n  &:active:focus {\n    color: $black-active;\n  }\n}\n","$orange-yellow: #ffb600;\n$orange-yellow-hover: #ffc533;\n$orange-yellow-active: #f4af02;\n$orange-yellow-disabled: #fff0cc;\n$orange-yellow-10: #fff8e6;\n$orange: #ff8a00;\n$orange-10: #fff3e6;\n$black: #242424;\n$black-active: #000;\n$gray-80: #505050;\n$gray-60: #7c7c7c;\n$gray-40: #a7a7a7;\n$gray-30: #bdbdbd;\n$gray-20: #d3d3d3;\n$gray-10: #e9e9e9;\n$gray-5: #f4f4f4;\n$gray-background: #f9f9f9;\n$white: #fff;\n$red: #ff3a3a;\n$red-10: #ffebeb;\n$green: #3bd520;\n$green-disabled: #c4f2bc;\n$green-10: #ebfbe9;\n$yellow: #ffdc22;\n$yellow-10: #fffbe9;\n$blue: #00b2ff;\n$blue-active: #00a0e6;\n$blue-hover: #33c2ff;\n$blue-disabled: #ccf0ff;\n$blue-10: #e6f7ff;\n$dark-blue: #06f;\n$dark-blue-10: #e6f0ff;\n$purple: #a500f2;\n$purple-10: #f6e6fe;\n$pink: #ff4ced;\n$pink-10: #ffedfd;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Link_link__-xO8v`,
	"blue": `Link_blue__uFaxN`,
	"black": `Link_black__t3bPl`
};
export default ___CSS_LOADER_EXPORT___;
