import { DefaultOption } from "shared/ui/Select";

export const mapToDefaultOptions = (
  data: Record<string, string>,
  callback?: (value: Record<string, string>) => DefaultOption[],
): DefaultOption[] => {
  if (callback && typeof callback === "function") {
    return callback(data);
  }
  return Object.entries(data).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

export const getOption = (value: string | number, options: DefaultOption[]): DefaultOption | undefined => {
  return options.find((option) => option.value == value);
};
