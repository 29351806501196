import { get } from "../request";

import { mapToDefaultOptions } from "shared/utils";

export const AffiliateApi = () => {
  const getTopAffiliates = async (rating: number | null) => {
    return await get(`v1/top/affiliates?rating=${rating}`);
  };

  const getTopAffiliatesRating = async () => {
    const result = await get("v1/top/affiliate-rating");

    if (result.isSuccess && result.result) {
      return {
        ...result,
        result: mapToDefaultOptions(result.result, (data) => {
          return Object.entries(data).map(([key, value]) => ({
            value: Number(key),
            label: `Рейтинг по ${value}`,
          }));
        }),
      };
    }
    return result;
  };

  return {
    getTopAffiliates,
    getTopAffiliatesRating,
  };
};
