// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardAffiliates_affiliates__Vv-Pi {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.DashboardAffiliates_affiliates__Vv-Pi .DashboardAffiliates_text__7aziY {
  padding: 16px 0;
}

.DashboardAffiliates_loader__dO3IS {
  min-height: 95px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/DashboardAffiliates/DashboardAffiliates.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,gBAAA;AAAF","sourcesContent":[".affiliates {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  .text {\n    padding: 16px 0;\n  }\n}\n\n.loader {\n  min-height: 95px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliates": `DashboardAffiliates_affiliates__Vv-Pi`,
	"text": `DashboardAffiliates_text__7aziY`,
	"loader": `DashboardAffiliates_loader__dO3IS`
};
export default ___CSS_LOADER_EXPORT___;
