import { DefaultOption } from "shared/ui/Select";
import { getOption } from "shared/utils";

export const getValueFromOptions = (item: number | string | undefined, options: DefaultOption[]) => {
  if (item) return getOption(item, options);
};

export const getValuesFromOptions = (items: number[] | string[] | undefined, options: DefaultOption[]) => {
  if (items) {
    const result: DefaultOption[] = [];
    items.forEach((item) => {
      const findValue = getValueFromOptions(item, options);
      if (findValue) result.push(findValue);
    });
    return result;
  }
};

export const getValueFromFilters = (value: DefaultOption | number | string | undefined) => {
  if (value && typeof value === "object") return value.value;
  return value;
};

export const getValuesFromFilters = (values: DefaultOption[] | number[] | string[] | undefined) => {
  if (values) return values.map((value) => getValueFromFilters(value));
};
