import { FC } from "react";

import { ReactComponent as Help } from "@material-symbols/svg-400/rounded/help.svg";
import { ReactComponent as Sort } from "@material-symbols/svg-400/rounded/sort.svg";
import cn from "classnames";

import classes from "../Table.module.scss";
import { TableHeadCellContentProps, TableHeadCellProps, ParentColumnsProps } from "../Table.types";

import { BaseButton } from "shared/ui/BaseButton";
import { Checkbox } from "shared/ui/Checkbox";
import { Tooltip } from "shared/ui/Tooltip";

export const TableHeadRow: FC<any> = (props) => {
  const { children, parentColumns } = props;

  return (
    <>
      {parentColumns && (
        <tr>
          {parentColumns.map((column: ParentColumnsProps) => (
            <th
              key={column.id}
              className={cn(classes["table-head-cell"], column.isEmpty && classes.empty)}
              colSpan={column.colSpan}
              rowSpan={column.rowSpan}
            >
              <div className={cn(classes["table-head-cell-content"], classes[`table-head-cell-${column.align}`])}>
                {column.title}
              </div>
            </th>
          ))}
        </tr>
      )}
      <tr {...props}>{children}</tr>
    </>
  );
};

export const TableHeadCell: FC<TableHeadCellProps> = ({
  children,
  dataIndex,
  isSortable,
  sortingKey,
  sortingOrder,
  rowSelection,
  totalCount,
  tooltip,
  nowrap = false,
  ellipsis = false,
  align = "center",
  width,
  handleSelectAll,
  handleSorting,
}) => {
  if (rowSelection) {
    const { selectedIds } = rowSelection;
    const isCheckAll = selectedIds.length === totalCount;
    const isIndeterminate = selectedIds.length < totalCount && selectedIds.length > 0;

    return (
      <th className={classes["table-head-cell"]} style={{ width: width }}>
        <Checkbox
          className={cn(classes["table-head-cell-content"], classes.checkbox)}
          checked={isCheckAll || isIndeterminate}
          onChange={handleSelectAll}
          isIndeterminate={isIndeterminate}
        />
      </th>
    );
  }
  return (
    <th
      data-testid="table-head-cell-test"
      className={cn(classes["table-head-cell"], nowrap && classes.nowrap)}
      style={{ width: width }}
    >
      <TableHeadCellContent
        align={align}
        dataIndex={dataIndex}
        sortingKey={sortingKey}
        handleSorting={handleSorting}
        width={width}
        ellipsis={ellipsis}
        isSortable={isSortable}
        sortingOrder={sortingOrder}
        tooltip={tooltip}
      >
        {children}
      </TableHeadCellContent>
    </th>
  );
};

export const TableHeadCellContent: FC<TableHeadCellContentProps> = ({
  children,
  dataIndex,
  isSortable,
  sortingKey,
  sortingOrder,
  ellipsis,
  width,
  tooltip,
  align = "center",
  handleSorting,
}) => {
  let styles: any;
  if (ellipsis)
    styles = { width: width, display: "block", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" };
  return (
    <div
      data-testid="table-head-cell-content-test"
      style={{ ...styles }}
      className={cn(classes["table-head-cell-content"], classes[`table-head-cell-${align}`])}
    >
      {!tooltip && isSortable && align === "right" && (
        <BaseButton
          onClick={() => {
            handleSorting(dataIndex);
          }}
          dataTestid="sort-button-test"
          className={cn(classes["sort-button"], dataIndex === sortingKey && classes[`sort-button-${sortingOrder}`])}
        >
          <Sort />
        </BaseButton>
      )}
      {tooltip && !isSortable && align === "right" && (
        <Tooltip content={tooltip} place="top" targetClassName={cn(classes["help-icon"])} className={classes.tooltip}>
          <Help />
        </Tooltip>
      )}
      {children}
      {tooltip && !isSortable && align !== "right" && (
        <Tooltip content={tooltip} place="top" targetClassName={cn(classes["help-icon"])} className={classes.tooltip}>
          <Help />
        </Tooltip>
      )}
      {!tooltip && isSortable && align !== "right" && (
        <BaseButton
          onClick={() => {
            handleSorting(dataIndex);
          }}
          dataTestid="sort-button-test"
          className={cn(classes["sort-button"], dataIndex === sortingKey && classes[`sort-button-${sortingOrder}`])}
        >
          <Sort />
        </BaseButton>
      )}
    </div>
  );
};
