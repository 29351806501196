import { FC, useState } from "react";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { useStore } from "entities/context";

import classes from "./SetupDashboard.module.scss";
import { SetupDashboardProps } from "./SetupDashboard.types";

import { Button } from "shared/ui/Button";
import { Checkbox } from "shared/ui/Checkbox";
import { Text } from "shared/ui/Text";

export const SetupDashboard: FC<SetupDashboardProps> = ({ setOpen }) => {
  const [checkAll, setCheckAll] = useState(false);
  const {
    dashboardStore: { visibleItems, updateVisibility },
  } = useStore();
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: { ...visibleItems },
  });

  const onSubmit: SubmitHandler<any> = async (newData) => {
    updateVisibility(newData);
    setOpen(false);
  };

  const handleCheckAll = () => {
    if (checkAll) {
      setValue("topOffers", false);
      setValue("topAffiliates", false);
      setCheckAll(false);
    } else {
      setValue("topOffers", true);
      setValue("topAffiliates", true);
      setCheckAll(true);
    }
  };

  return (
    <>
      <Text size="lg" height="big" className={classes.text}>
        Выберите блоки, которые необходимы.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.fields}>
          <Checkbox
            label="Отобразить все"
            checked={checkAll || (watch("manager") && watch("topOffers") && watch("topAffiliates"))}
            onChange={handleCheckAll}
          />
          <Controller
            name="manager"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox disabled label="Личный менеджер" checked={Boolean(value)} onChange={onChange} />
            )}
          />
          <Controller
            name="topOffers"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox label="Топ офферов" checked={Boolean(value)} onChange={onChange} />
            )}
          />
          <Controller
            name="topAffiliates"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox label="Топ вебмастеров" checked={Boolean(value)} onChange={onChange} />
            )}
          />
        </div>
        <div className={classes.buttons}>
          <Button onClick={() => setOpen(false)} theme="secondary" size="medium" type="button">
            Отмена
          </Button>
          <Button size="medium" type="submit">
            Применить
          </Button>
        </div>
      </form>
    </>
  );
};
