import React, { FC } from "react";

interface Props {
  src: string;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
}

export const Image: FC<Props> = React.memo(({ src, alt = "", className, width, height }) => {
  return <img src={src} alt={alt} className={className} width={width || "auto"} height={height || "auto"} />;
});
