import { FC } from "react";

import cn from "classnames";

import classes from "../BaseModal.module.scss";
import { FooterProps } from "../BaseModal.types";

import { Button } from "shared/ui/Button";

export const Footer: FC<FooterProps> = ({
  targetBtnText,
  cancelBtnText,
  footerClassName,
  targetBtnClassName,
  disabledTarget,
  targetBtnHandler,
  cancelBtnClassName,
  setOpen,
  cancelBtnHandler,
}) => {
  if (Boolean(cancelBtnText) || Boolean(targetBtnText)) {
    const cancelHandler = () => {
      setOpen && setOpen(false);
      cancelBtnHandler && cancelBtnHandler();
    };
    const targetHandler = () => targetBtnHandler && targetBtnHandler();

    return (
      <div data-testid="modal-footer" className={cn(classes.footer, footerClassName)}>
        {Boolean(cancelBtnText) && (
          <Button theme="secondary" size="medium" className={cancelBtnClassName} onClick={cancelHandler}>
            {cancelBtnText}
          </Button>
        )}
        {Boolean(targetBtnText) && (
          <Button size="medium" disabled={disabledTarget} className={targetBtnClassName} onClick={targetHandler}>
            {targetBtnText}
          </Button>
        )}
      </div>
    );
  }
  return null;
};
