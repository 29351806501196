import { AffiliateStore } from "./Affiliate/store";
import { DashboardStore } from "./Dashboard/store";
import { ManagerStore } from "./Manager/store";
import { OfferStore } from "./Offer/store";
import { StatisticsDictionaryStore } from "./StatisticsDictionary/store";
import { StatisticsFiltersStore } from "./StatisticsFilters/store";
import { UserStore } from "./User/store";

export class RootStore {
  userStore = new UserStore();
  dashboardStore = new DashboardStore();
  managerStore = new ManagerStore();
  affiliateStore = new AffiliateStore();
  offerStore = new OfferStore();
  statisticsDictionaryStore = new StatisticsDictionaryStore();
  statisticsFiltersStore = new StatisticsFiltersStore();
}
