import { FC } from "react";

import cn from "classnames";
import ReactSelect from "react-select";

import { ClearIndicator, DropdownIndicator, NoOptionsMessage } from "./components";
import classes from "./Select.module.scss";
import { SelectProps } from "./Select.types";

export const Select: FC<SelectProps> = ({
  className,
  options,
  defaultValue,
  value,
  size = "normal",
  isSearchable = false,
  isClearable = true,
  isDisabled = false,
  placeholder = "",
  onChange,
  ...props
}) => {
  return (
    <ReactSelect
      className={className}
      classNames={{
        container: () => classes.container,
        control: ({ isDisabled, menuIsOpen }) =>
          cn(
            classes.control,
            classes[`single-fixed-${size}`],
            classes[`control-${size}`],
            isDisabled && classes.disabled,
            menuIsOpen && classes.open,
          ),
        option: ({ isDisabled, isFocused, isSelected }) =>
          cn(
            classes.option,
            classes[size],
            isSelected && classes.selected,
            isFocused && classes.focused,
            isDisabled && classes.disabled,
          ),
        valueContainer: () => classes.value,
        singleValue: () => classes[`value-single-${size}`],
        placeholder: () => classes.placeholder,
        input: ({ isHidden, isDisabled }) =>
          cn(classes.input, classes[size], isHidden && classes.hidden, isDisabled && classes.disabled),
        indicatorsContainer: () => cn(classes["indicator-container"], classes[`indicator-${size}`]),
        menu: () => classes.menu,
        menuList: () => classes["menu-list"],
      }}
      maxMenuHeight={230}
      unstyled
      options={options}
      isDisabled={isDisabled}
      value={value}
      defaultValue={defaultValue}
      isSearchable={isSearchable}
      isClearable={isClearable}
      onChange={onChange}
      components={{
        DropdownIndicator: DropdownIndicator,
        ClearIndicator: ClearIndicator,
        IndicatorSeparator: null,
        NoOptionsMessage: NoOptionsMessage,
      }}
      placeholder={placeholder}
      {...props}
    />
  );
};
