export type QueryType = Record<string, any>;

export const convertQueryStringToObject = (query: string): QueryType => {
  const params = new URLSearchParams(query);
  const result: QueryType = {};
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export const convertObjectToQueryString = (query: QueryType): string => {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length) {
      value.forEach((val) => {
        if (val && typeof val !== "object") params.append(`${key}[]`, val);
      });
    }
    if (typeof value !== "object" && typeof value !== "undefined" && value !== "") {
      params.append(key, value);
    }
  });
  if (params.size) {
    return "?" + params.toString();
  }
  return "";
};
