import { FC, useEffect, useMemo } from "react";

import { ReactComponent as ArrowIcon } from "@material-symbols/svg-400/rounded/east.svg";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";

import classes from "./DashboardAffiliates.module.scss";

import { Button } from "shared/ui/Button";
import { Loader } from "shared/ui/Loader";
import { ColumnProps, Table } from "shared/ui/Table";
import { Text } from "shared/ui/Text";

export const DashboardAffiliates: FC = observer(() => {
  const columns: ColumnProps[] = useMemo(
    () => [
      {
        id: 0,
        align: "left",
        key: "id",
        title: "ID вебмастера",
        width: 120,
      },
      {
        id: 1,
        align: "right",
        key: "cr",
        title: "CR%",
        width: 85,
      },
      {
        id: 2,
        align: "right",
        key: "ar",
        title: "AR%",
        width: 85,
      },
      {
        id: 3,
        align: "right",
        key: "epc",
        title: "EPC",
        width: 85,
      },
    ],
    [],
  );
  const {
    affiliateStore: { loading, currentRating, tableLoading, getTopAffiliates, loadTopAffiliates },
  } = useStore();

  useEffect(() => {
    if (currentRating !== null) {
      loadTopAffiliates();
    }
  }, [currentRating]);

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader icon loading size="normal" text={false} absolute />
      </div>
    );
  }

  return (
    <div className={classes.affiliates}>
      {!getTopAffiliates().length ? (
        <Text size="lg" height="big" className={classes.text}>
          Нет данных.
          <br />
          Пока никто не работает с вашими офферами
        </Text>
      ) : (
        <Table columns={columns} data={getTopAffiliates()} isLoading={tableLoading} />
      )}
      <Button
        disabled={getTopAffiliates().length === 0}
        href="/statistics/affiliates"
        theme="secondary"
        size="medium"
        iconRight={<ArrowIcon />}
      >
        Статистика по вебмастерам
      </Button>
    </div>
  );
});
