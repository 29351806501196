import { makeAutoObservable } from "mobx";

import { DictionaryProps } from "./model";

import { StatisticsDictionaryApi } from "shared/api";
import { Result } from "shared/api/request";

const { getOffers, getTimeZone, getAffiliates, getOpponents, getOpponentsAffiliates } = StatisticsDictionaryApi();

export class StatisticsDictionaryStore {
  offers: DictionaryProps = null;
  timeZone: DictionaryProps = null;
  affiliates: DictionaryProps = null;
  opponents: DictionaryProps = null;
  opponentsAffiliates: DictionaryProps = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadOffers = async () => {
    if (!this.offers) {
      const result: Result = await getOffers();
      if (result.isSuccess) {
        this.offers = [...result.result];
      } else {
        this.offers = [];
      }
    }
  };

  loadAffiliates = async () => {
    if (!this.affiliates) {
      const result: Result = await getAffiliates();
      if (result.isSuccess) {
        this.affiliates = [...result.result];
      } else {
        this.affiliates = [];
      }
    }
  };

  loadTimeZone = async () => {
    if (!this.timeZone) {
      const result: Result = await getTimeZone();
      if (result.isSuccess) {
        this.timeZone = [...result.result];
      } else {
        this.timeZone = [];
      }
    }
  };

  loadOpponents = async () => {
    if (!this.opponents) {
      const result: Result = await getOpponents();
      if (result.isSuccess) {
        this.opponents = [...result.result];
      } else {
        this.opponents = [];
      }
    }
  };

  loadOpponentsAffiliates = async (id: number) => {
    const result: Result = await getOpponentsAffiliates(id);
    if (result.isSuccess) {
      this.opponentsAffiliates = [...result.result];
    } else {
      this.opponentsAffiliates = [];
    }
  };
}
