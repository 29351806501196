import { get, post } from "../request";

export const StatisticsFiltersApi = () => {
  const getDefaultFilter = async () => {
    return await get(`v1/statistic/default-filter`);
  };
  const getFilter = async (hash: string) => {
    return await get(`v1/statistic/get-filter?hash=${hash}`);
  };
  const saveFilter = async (data: any) => {
    return await post(`v1/statistic/save-filter`, data);
  };

  return { getDefaultFilter, getFilter, saveFilter };
};
