import { FC, useEffect } from "react";

import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";
import { Info, Photo } from "entities/Manager/ui";

import classes from "./DashboardManager.module.scss";

import { Loader } from "shared/ui/Loader";

export const DashboardManager: FC = observer(() => {
  const {
    managerStore: { manager, loading, load },
  } = useStore();

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return (
      <div className={classes.manager}>
        <Loader icon loading size="normal" text={false} absolute />
      </div>
    );
  }

  return (
    <div className={classes.manager}>
      <Photo url={manager?.imageUrl} />
      {manager && <Info {...manager} />}
    </div>
  );
});
