import { FC, useMemo, useState } from "react";

import { ReactComponent as Arrow } from "@material-symbols/svg-400/rounded/arrow_forward_ios.svg";
import cn from "classnames";
import TableComponent from "rc-table";
import { RenderExpandIconProps } from "rc-table/lib/interface";

import { BaseButton } from "../BaseButton";
import { Loader } from "../Loader";

import { TableBodyCell } from "./components/TableBody";
import { TableHeadCell, TableHeadRow } from "./components/TableHead";
import classes from "./Table.module.scss";
import { TableProps } from "./Table.types";
import { setupColumns } from "./utils";

export const Table: FC<TableProps> = ({
  data,
  columns,
  summaryData,
  highlightRow,
  rowSelection,
  rowClassName,
  parentColumns,
  tableClassName,
  sortingKey = "",
  sortingOrder = "def",
  isLoading = false,
  expandableRows = false,
  emptyDataText = "Ничего не найдено.",
  onSort,
}) => {
  const tableClassNames = cn(classes.table, isLoading && classes.loading, tableClassName);
  const rowClassNames = cn(classes.row, highlightRow && classes.highlight, rowClassName);
  const [rowKeys, setRowKeys] = useState<any[]>([]);

  const tableColumns: any = useMemo(() => {
    if (data.length === 0) {
      return setupColumns(
        columns.map((column) => ({ ...column, colSpan: (index) => (index === 0 ? columns.length : 0) })),
        sortingOrder,
        sortingKey,
        rowSelection,
      );
    }
    if (expandableRows) {
      return setupColumns(
        [
          {
            id: 11,
            align: "center",
            title: "",
            key: "expand",
            width: 42,
          },
          ...columns,
        ],
        sortingOrder,
        sortingKey,
        rowSelection,
      );
    }
    return setupColumns(columns, sortingOrder, sortingKey, rowSelection);
  }, [columns, sortingOrder, sortingKey, rowSelection, data, rowKeys]);

  const tableData = useMemo(() => {
    let newData = data;
    if (rowSelection) {
      newData = data.map((row: any) => {
        return { ...row, checkbox: true };
      });
    }
    if (summaryData) {
      newData = [...newData, { ...summaryData, checkbox: false, isSummary: true }];
    }
    return [...newData];
  }, [data, summaryData, rowKeys]);

  const expandIcon = (props: RenderExpandIconProps<any>) => {
    const record = props.record;
    const expanded = props.expanded;

    if (record.children) {
      return (
        <BaseButton
          dataTestid={`expand-${record.key}-test`}
          className={classes["expand-button"]}
          onClick={() => handleExapnd(record.key)}
        >
          <Arrow className={cn(classes.icon, expanded && classes.expanded)} />
        </BaseButton>
      );
    }
    return;
  };

  const handleSorting = (key: string) => {
    if (key !== sortingKey) {
      onSort && onSort("desc", key);
      return;
    }
    switch (sortingOrder) {
      case "desc":
        onSort && onSort("asc", key);
        break;
      case "asc":
        onSort && onSort("def", key);
        break;
      case "def":
        onSort && onSort("desc", key);
        break;
      default:
        break;
    }
  };

  const handleSelectAll = () => {
    if (rowSelection) {
      const { selectedIds, onRowSelect } = rowSelection;

      if (selectedIds.length !== data.length) {
        const ids = data.map((el: any) => el.id);
        onRowSelect([...ids]);
      } else {
        onRowSelect([]);
      }
    }
  };

  const onExpandedRowsChange = (expandedKeys: any) => {
    setRowKeys(expandedKeys);
  };

  const handleExapnd = (key: any) => {
    if (rowKeys.includes(key)) {
      setRowKeys([...rowKeys.filter((item) => item !== key)]);
      return;
    }
    setRowKeys([...rowKeys, key]);
  };

  const setupExpandedRow = () => {
    if (expandableRows) {
      return {
        expandable: {
          expandIcon: (props: RenderExpandIconProps<any>) => expandIcon(props),
          columnTitle: "",
          expandedRowKeys: rowKeys,
          onExpandedRowsChange,
          expandIconColumnIndex: 0,
        },
      };
    }
    return undefined;
  };

  return useMemo(() => {
    return (
      <div className={classes["table-container"]}>
        <TableComponent
          components={{
            header: {
              row: (props: any) => <TableHeadRow {...props} parentColumns={parentColumns} />,
              cell: (props: any) => (
                <TableHeadCell
                  {...props}
                  totalCount={data.length}
                  handleSorting={handleSorting}
                  handleSelectAll={handleSelectAll}
                />
              ),
            },
            body: {
              cell: (props: any) => {
                return <TableBodyCell {...props} />;
              },
            },
          }}
          tableLayout="fixed"
          columns={tableColumns}
          data={tableData}
          emptyText={emptyDataText}
          className={tableClassNames}
          rowClassName={rowClassNames}
          {...setupExpandedRow()}
        />
        {isLoading && <Loader icon loading absolute size="large" text={false} />}
      </div>
    );
  }, [tableColumns, data, isLoading, highlightRow, summaryData]);
};
