import { FC, ReactNode, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useDocumentTitle } from "usehooks-ts";

import { useStore } from "entities/context";

import { PageSkeleton } from "./PageSkeleton";

import { DashboardAffiliates } from "widgets/DashboardAffiliates";
import { DashboardDnD } from "widgets/DashboardDnD";
import { ItemProps } from "widgets/DashboardDnD/DnD.types";
import { DashboardManager } from "widgets/DashboardManager/DashboardManager";
import { DashboardOffers } from "widgets/DashboardOffers";

interface SettingsProps {
  topAffiliates: SettingProps;
  topOffers: SettingProps;
  manager: SettingProps;
}

interface SettingProps {
  title: string;
  children: ReactNode;
}

export const DashboardPage: FC = observer(() => {
  const [dashboardItems, setDashboardItems] = useState<ItemProps[]>([]);
  const {
    dashboardStore: { data, loading, load },
    affiliateStore: { loadTopAffiliatesRating },
    offerStore: { loadTopOffersRating },
  } = useStore();

  const setting: SettingsProps = {
    topAffiliates: { title: "Топ вебмастеров", children: <DashboardAffiliates /> },
    topOffers: { title: "Топ офферов", children: <DashboardOffers /> },
    manager: { title: "Ваш личный менеджер", children: <DashboardManager /> },
  };

  useDocumentTitle("Главная | Guruleads");
  useEffect(() => {
    load();
    loadTopAffiliatesRating();
    loadTopOffersRating();
  }, []);

  useEffect(() => {
    if (!loading && data.length > 0) {
      const array: any = data.map((item) => {
        return {
          ...item,
          title: setting[item.name as keyof SettingsProps].title,
          children: setting[item.name as keyof SettingsProps].children,
          variant: "dashboard",
          isDraggable: true,
        };
      });

      setDashboardItems(array.sort((a: any, b: any) => a.column - b.column));
    }
  }, [data, loading]);

  if (loading) {
    return <PageSkeleton />;
  }

  return <DashboardDnD items={dashboardItems} setItems={setDashboardItems} />;
});
