import { FC, ReactNode, CSSProperties, forwardRef } from "react";

import cn from "classnames";

import classes from "./Card.module.scss";

interface Props {
  /** Содержимое карточки */
  children: ReactNode;
  /** Класс для кастомизации */
  className?: string;
  /** Атрибут стиля */
  style?: CSSProperties;
  ref?: any;
}

export const Card: FC<Props> = forwardRef(({ className, children, style }, ref: any) => {
  return (
    <div ref={ref} className={cn(classes.container, className)} style={{ ...style }}>
      {children}
    </div>
  );
});
