import { Navigate, Route, createRoutesFromElements } from "react-router-dom";
import uniqid from "uniqid";

import { DashboardPage } from "pages/DashboardPage";

import { MainLayout } from "shared/layouts/MainLayout";
import { StatisticOpponentsAffiliatesProvider } from "shared/providers/StatisticOpponentsAffiliatesProvider";

export const mainRoutes = createRoutesFromElements(
  <Route element={<MainLayout />}>
    <Route id={uniqid()} path="/" element={<Navigate to="/dashboard" />} />
    <Route id={uniqid()} key={uniqid()} path="/dashboard" element={<DashboardPage />} />
    <Route
      id={uniqid()}
      key={uniqid()}
      path="/offers"
      lazy={async () => {
        const { OffersPage } = await import("pages/OffersPage");
        return { Component: OffersPage };
      }}
    />
    <Route id={uniqid()} key={uniqid()} path="/statistics" element={<Navigate to="/statistics/offers" />} />
    <Route id={uniqid()} key={uniqid()} path="/statistics">
      <Route
        id={uniqid()}
        key={uniqid()}
        path="offers"
        lazy={async () => {
          const { StatisticsOffersPage } = await import("pages/StatisticsOffersPage");
          return { Component: StatisticsOffersPage };
        }}
      />
      <Route
        id={uniqid()}
        key={uniqid()}
        path="affiliates"
        lazy={async () => {
          const { StatisticsAffiliatesPage } = await import("pages/StatisticsAffiliatesPage");
          return { Component: StatisticsAffiliatesPage };
        }}
      />
      <Route
        id={uniqid()}
        key={uniqid()}
        path="opponents"
        lazy={async () => {
          const { StatisticsOpponentsPage } = await import("pages/StatisticsOpponentsPage");
          return { Component: StatisticsOpponentsPage };
        }}
      />
      <Route
        id={uniqid()}
        key={uniqid()}
        path="opponents/:id"
        lazy={async () => {
          const { StatisticsOpponentsAffiliatesPage } = await import("pages/StatisticsOpponentsAffiliatesPage");
          return {
            Component: () => (
              <StatisticOpponentsAffiliatesProvider>
                <StatisticsOpponentsAffiliatesPage />
              </StatisticOpponentsAffiliatesProvider>
            ),
          };
        }}
      />
    </Route>
    <Route
      id={uniqid()}
      key={uniqid()}
      path="/profile/edit-password"
      lazy={async () => {
        const { EditUserPasswordPage } = await import("pages/EditUserPasswordPage");
        return { Component: EditUserPasswordPage };
      }}
    />
    <Route
      id={uniqid()}
      key={uniqid()}
      path="*"
      lazy={async () => {
        const { Page404 } = await import("pages/Page404");
        return { Component: Page404 };
      }}
    />
  </Route>,
);
