import { get } from "../request";

export const ManagerApi = () => {
  const getManager = async () => {
    return await get("v1/manager");
  };

  return {
    getManager,
  };
};
