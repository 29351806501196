import { FC, ReactNode, CSSProperties } from "react";

import cn from "classnames";

import classes from "../Grid.module.scss";

import { IRow } from "./types";
import { getGridClasses, getGapStyles } from "./utils";

interface Props extends IRow {
  children?: ReactNode;
  style?: CSSProperties;
  className?: string;
  /** gap, wrap, direction, align, justify для экрана <= 576px */
  sm?: IRow;
  /** gap, wrap, direction, align, justify для экрана <= 768px */
  md?: IRow;
  /** gap, wrap, direction, align, justify для экрана <= 992px */
  lg?: IRow;
  /** gap, wrap, direction, align, justify для экрана <= 1200px */
  xl?: IRow;
  /** gap, wrap, direction, align, justify для экрана <= 1400px */
  xxl?: IRow;
}

/**
 * ### Разработка
 * В компоненте `Grid` использована 12-колоночная система сетки.
 * Для формирования колонок используются компоненты `Col`.
 *
 * - `wrap` -- свойство используется для включения/отключения переноса колонок на новую строку, по умолчанию включено.
 * - `direction` -- направление колонок в сетке, по умолчанию в строку слева направо (`row`). Также можно изменить на -- в строку справа налево (`row-reverse`), в колонку сверху вниз (`column`), в колонку снизу вверх (`column-reverse`).
 * - `align` -- выравнивание колонок по вертикали, по умолчанию -- по верхней границе сетки. Возможные значения -- по верхней границе сетки (`start`), по нижней границе (`end`), в центре (`center`), по высоте (`stretch`).
 * - `justify` -- выравнивание колонок по горизонталиб по умолчанию -- в начале строки. Возможные значения -- в начале строки (`start`), в конце строки (`end`), по центру (`center`), с одинаковым расстоянием между колонками (`between`), с одинаковым расстоянием между колонками и границами сетки (`evenly`), с одинаковым расстоянием вокруг колонок (`around`).
 * - `gap` -- отступы между колонками, по умолчанию - `24px`. Можно задать одно число, тогда вертикальные и горизонтальные отступы будут одинаковыми. Можно задать с помощью массива из 2-х чисел - первое число - вертикальные отступы, второе - горизонтальные.
 *
 * Используются стандартные брейкпоинты - пропы `sm`, `md`, `lg`, `xl`, `xxl`. В них можно указать свойства `wrap`, `direction`, `align`, `justify`, `gap`, передаются объектом с соответствующими ключами.
 *
 * ```js
 * {
 *      wrap: false,
 *      direction: "column",
 *      align: "center",
 *      justify: "between",
 *      gap: [10, 20]
 * }
 * ```
 *
 * Для кастомизации можно использовать свойство `className`, для инлайновых стилей - свойство `style`.
 *
 */
export const Grid: FC<Props> = ({
  children,
  className,
  style = {},
  gap = 24,
  wrap = true,
  direction,
  align,
  justify,
  sm = {},
  md = {},
  lg = {},
  xl = {},
  xxl = {},
}) => {
  const gapStyle: CSSProperties = { ...getGapStyles({ gap }, { gap: 20, ...sm }, md, lg, xl, xxl) };
  const classNames = cn(
    classes.grid,
    getGridClasses(classes, { wrap, direction, align, justify }, sm, md, lg, xl, xxl),
    className,
  );

  return (
    <div
      style={{
        ...style,
        ...gapStyle,
      }}
      className={classNames}
    >
      {children}
    </div>
  );
};
