import { createContext, useContext } from "react";

import { StatisticsOpponentsStore } from "./store";

export const StatisticsOpponentsStoreContext = createContext<StatisticsOpponentsStore | null>(null);

export const useStatisticOpponentsStore = () => {
  const context = useContext(StatisticsOpponentsStoreContext);
  if (context === null) {
    throw new Error();
  }
  return context;
};
