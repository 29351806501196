import { FC, useEffect, useState } from "react";

import cn from "classnames";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  ClearIndicator,
  DropdownIndicator,
  Group,
  ValueContainer,
  Option,
  FormatGroupLabel,
  NoOptionsMessage,
  MultiValueContainer,
  MultiValueRemoveIndicator,
  TagValueContainer,
} from "./components";
import classes from "./Select.module.scss";
import { MultiSelectProps, OptionsToShow, SetupOptions } from "./Select.types";

const setupOptions = ({ value, options, setOptions }: SetupOptions) => {
  setOptions([
    {
      label: "Chosen",
      options: [...value],
    },
    {
      label: "Not chosen",
      options: options.filter((option) => !value.some((val) => JSON.stringify(option) === JSON.stringify(val))),
    },
  ]);
};

export const MultiSelect: FC<MultiSelectProps> = ({
  className,
  options,
  value,
  maxItems,
  placeholder = "",
  defaultValue = [],
  size = "normal",
  isSearchable = false,
  isClearable = true,
  isDisabled = false,
  isCreatable = false,
  closeMenuOnSelect = false,
  multiVariant = true,
  onChange,
  ...props
}) => {
  const [optionsToShow, setOptionsToShow] = useState<OptionsToShow[]>();

  useEffect(() => {
    if (value) {
      setupOptions({ value, options, setOptions: setOptionsToShow });
    }
  }, [value]);

  useEffect(() => {
    setupOptions({ value: defaultValue, options, setOptions: setOptionsToShow });
  }, []);

  const Tag = !isCreatable ? ReactSelect : CreatableSelect;

  return (
    <Tag
      className={className}
      classNames={{
        container: () => classes.container,
        control: ({ isDisabled, menuIsOpen }) =>
          cn(classes.control, classes[`control-${size}`], isDisabled && classes.disabled, menuIsOpen && classes.open),
        option: ({ isDisabled, isFocused, isSelected }) =>
          cn(
            classes.option,
            classes[size],
            isSelected && classes.selected,
            isFocused && classes.focused,
            maxItems && value && value.length > maxItems && !isSelected && classes.disabled,
          ),
        valueContainer: () => cn(classes.value, classes[`value-${size}`]),
        placeholder: () => classes.placeholder,
        input: ({ isHidden, isDisabled }) =>
          cn(classes.input, classes[size], isHidden && classes.hidden, isDisabled && classes.disabled),
        indicatorsContainer: () => cn(classes["indicator-container"], classes[`indicator-${size}`]),
        menu: () => classes.menu,
        menuList: () => classes["menu-list"],
        multiValue: () => cn(classes["multi-value"]),
      }}
      maxMenuHeight={200}
      unstyled
      options={optionsToShow}
      isMulti
      createOptionPosition="first"
      value={value}
      defaultValue={defaultValue}
      openMenuOnClick
      closeMenuOnSelect={closeMenuOnSelect}
      backspaceRemovesValue={false}
      isSearchable={isSearchable}
      hideSelectedOptions={false}
      isClearable={isClearable}
      isDisabled={isDisabled}
      onChange={onChange}
      formatGroupLabel={FormatGroupLabel}
      components={{
        ValueContainer: multiVariant ? ValueContainer : TagValueContainer,
        DropdownIndicator: DropdownIndicator,
        ClearIndicator: ClearIndicator,
        Option: Option,
        Group: Group,
        IndicatorSeparator: null,
        NoOptionsMessage: NoOptionsMessage,
        MultiValueContainer: (props) => <MultiValueContainer size={size} {...props} />,
        MultiValueRemove: (props) => <MultiValueRemoveIndicator size={size} {...props} />,
      }}
      placeholder={placeholder}
      {...props}
    />
  );
};
