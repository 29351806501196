import { FC, useEffect, useMemo } from "react";

import { ReactComponent as ArrowIcon } from "@material-symbols/svg-400/rounded/east.svg";
import { ReactComponent as ImageIcon } from "@material-symbols/svg-400/rounded/image.svg";
import { observer } from "mobx-react-lite";

import { useStore } from "entities/context";

import classes from "./DashboardOffers.module.scss";

import { Button } from "shared/ui/Button";
import { Image } from "shared/ui/Image";
import { Loader } from "shared/ui/Loader";
import { ColumnProps, Table, DataProps } from "shared/ui/Table";
import { Text } from "shared/ui/Text";

export const DashboardOffers: FC = observer(() => {
  const columns: ColumnProps[] = useMemo(
    () => [
      {
        id: 0,
        align: "left",
        key: "id",
        title: "ID",
        width: 56,
      },
      {
        id: 1,
        align: "left",
        key: "logo",
        title: "",
        width: 92,
      },
      {
        id: 2,
        align: "right",
        key: "cr",
        title: "CR%",
        width: 76,
      },
      {
        id: 3,
        align: "right",
        key: "ar",
        title: "AR%",
        width: 76,
      },
      {
        id: 4,
        align: "right",
        key: "epc",
        title: "EPC",
        width: 76,
      },
    ],
    [],
  );

  const {
    offerStore: { topOffers, currentRating, loading, tableLoading, loadTopOffers },
  } = useStore();

  const data: DataProps[] = useMemo(
    () =>
      topOffers
        ? topOffers?.map((topOffer, i) => ({
            ...topOffer,
            logo: topOffer.logo ? (
              <Image src={topOffer.logo} className={classes.logo} />
            ) : (
              <ImageIcon className={classes.icon} />
            ),
            key: `top-offer-${topOffer.id}-${i}`,
          }))
        : [],
    [topOffers],
  );

  useEffect(() => {
    if (currentRating !== null) {
      loadTopOffers();
    }
  }, [currentRating]);

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader icon loading size="normal" text={false} absolute />
      </div>
    );
  }

  return (
    <div className={classes.offers}>
      {!topOffers || topOffers.length === 0 ? (
        <Text size="lg" height="big" className={classes.text}>
          Нет данных.
          <br />
          Попробуйте позже или обратитесь к менеджеру
        </Text>
      ) : (
        <Table columns={columns} data={data} isLoading={tableLoading} />
      )}
      <Button
        disabled={topOffers?.length === 0}
        href="/statistics/opponents"
        theme="secondary"
        size="medium"
        iconRight={<ArrowIcon />}
      >
        Статистика по конкурентам
      </Button>
    </div>
  );
});
