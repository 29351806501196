// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BaseLayout_container__hJgwx {
  padding-bottom: 100px;
}`, "",{"version":3,"sources":["webpack://./src/shared/layouts/BaseLayout/BaseLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".container {\n  padding-bottom: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BaseLayout_container__hJgwx`
};
export default ___CSS_LOADER_EXPORT___;
